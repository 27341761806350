import React from 'react';

import { Button, Tooltip } from 'antd';
import dayjs from 'dayjs';

import { getCurrentUTCTimezone } from 'helpers/date';
import DatePicker, { IDatePickerProps } from 'modules/common/containers/DatePicker';
import { translate } from 'modules/localization';

type IProps = IDatePickerProps & {
  onVisibleChange?: (isVisible: boolean) => void;
};

const StartTimeRateDatepicker = ({ onVisibleChange, ...rest }: IProps) => {
  return (
    <Tooltip title={getCurrentUTCTimezone()} placement="right" onOpenChange={onVisibleChange}>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/*// @ts-ignore*/}
      <DatePicker
        {...rest}
        getPopupContainer={(el) => el}
        showNow={false}
        renderExtraFooter={() => (
          <div className="picker-footer">
            <Button
              type="link"
              className="picker-now"
              onClick={() => rest.onChange(dayjs().add(1, 'minute'))}
            >
              {translate('now')}
            </Button>
          </div>
        )}
      />
    </Tooltip>
  );
};

export default StartTimeRateDatepicker;
