export const kk_translations: Record<string, string> = {
  // sections & entities
  planning: 'Жоспарлау',
  tariffing: 'Тарифталуы',
  directory: 'Анықтама',
  logout: 'Шығу',
  order: 'Тапсырыс',
  orders: 'Тапсырыстар',
  ordersList: 'Тапсырыстар тізімі',
  orderStatus: 'Тапсырыс дәрежесі',
  orderDeliveryDateTime: 'Күтілетін уақыт',
  orderSizes: 'Жүк параметрлері',
  orderType: 'Типі',
  orderDetails: 'Тапсырыс егжей-тегжейлері',
  taskTitle: 'Тапсырма',
  tasks: 'Тапсырмалар',
  tasksList: 'Тапсырмалар тізімі',
  taskType: 'Тапсырма типі',
  taskStatus: 'Тапсырма дәрежесі',
  taskDeliveryDateTime: 'Күтілетін уақыт',
  taskPlannedDeliveryDateTime: 'Жоспарлы уақыт',
  taskRealDeliveryDateTime: 'Нақты уақыт',
  taskSizes: 'Жүк параметрлері',
  removeTaskFromRoute: 'Маршруттан алып тастау',
  cancelTheTask: 'Тапсырманы жою',
  taskCancelation: 'Тапсырманы жою',
  finishTheTask: 'Аяқтау',
  route: 'Маршрут',
  routes: 'Маршруттар',
  routesList: 'Маршруттар тізімі',
  routeStatus: 'Маршрут дәрежесі',
  routeStartDate: 'Басталу күні',
  routeGenerator: 'Маршруттар генераторы',
  routePlannedDateTime: 'Жоспарлы уақыт',
  routeRealDateTime: 'Нақты уақыт',
  routeCreated: 'Маршрут құрылды',
  routeFormTitle: 'Маршрутты қалыптастыру',
  routeCreateTitle: 'Маршрутты құру',
  routeCalculated: 'Маршрут есептелді',
  routeDelete: 'Маршрутты өшіру',
  addTasksToRoute: 'Маршрутқа қосу',
  courier: 'Курьер',
  couriers: 'Курьерлар',
  couriersList: 'Курьерлар тізімі',
  courierEditSuccess: 'Курьер сәтті өзгертілді',
  courierCreateSuccess: 'Курьер сәтті құрылды',
  courierEditTitle: 'Курьерді өңдеу',
  courierCreateTitle: 'Курьерды қосу',
  deliveryMethod: 'Жеткізу тәсілі',
  deliveryMethods: 'Жеткізу тәсілдері',
  allDeliveryMethod: 'Барлық жеткізу тәсілдері',
  withoutDeliveryMethod: 'Жеткізусіз',
  deliveryMethodEditSuccess: 'Жеткізу тәсілі сәтті өзгертілді',
  deliveryMethodCreateSuccess: 'Жеткізу тәсілі сәтті құрылды',
  deliveryMethodEditTitle: 'Жеткізу тәсілін өңдеу',
  deliveryMethodCreateTitle: 'Жеткізу тәсілін қосу',
  deliveryMethodsParams: 'Жеткізу тәсілдерінің параметрлері',
  deliveryMethodTitle: 'Жеткізу тәсілінің атауы',
  deliveryMethodCode: 'Жеткізу тәсілінің коды',
  clientGroup: 'Клиенттер тобы',
  clientGroups: 'Клиенттер топтары',
  clientGroupEditSuccess: 'Клиенттер тобы сәтті өзгертілді',
  clientGroupCreateSuccess: 'Клиенттер тобы сәтті құрылды',
  clientGroupEditTitle: 'Клиенттер тобын өңдеу',
  clientGroupCreateTitle: 'Клиенттер тобын қосу',
  warehouse: 'Қойма',
  warehouses: 'Қоймалар',
  warehouseEdited: 'Қойма сәтті өзгертілді',
  warehouseCreated: 'Қойма сәтті құрылды',
  warehouseEditTitle: 'Қойманы өңдеу',
  warehouseCreateTitle: 'Қойманы қосу',
  warehouseHasNoCoords: 'Қойма коррдинаттары жоқ',
  warehouseSettingsEdited: 'Қойма баптаулары сәтті өзгертілді',
  client: 'Клиент',
  shop: 'Дүкен',
  shops: 'Дүкендер',
  shopEditSuccess: 'Дүкен сәтті өзгертілді',
  shopCreateSuccess: 'Дүкен сәтті құрылды',
  shopEditTitle: 'Дүкенді өңдеу',
  shopcCreateTitle: 'Дүкенді қосу',
  status: 'Мәртебесі',
  transport: 'Көлік',
  transportNumber: 'Мемлекеттік нөмірі',
  transportWeightWithUnit: 'Жүк көтерімділігі, кг',
  transportEditSuccess: 'Көлік сәтті өзгертілді',
  transportCreateSuccess: 'Көлік сәтті құрылды',
  transportEditTitle: 'Көлікті өңдеу',
  transportCreateTitle: 'Көлікті қосу',
  user: 'Қолданушы',
  users: 'Қолданушылар',
  userEdited: 'Қолданушы өзгертілді',
  userCreated: 'Қолданушы құрылды',
  userEditTitle: 'Қолданушыны өңдеу',
  userCreateTitle: 'Қолданушыны қосу',
  privacy: 'Құпиялылық саясаты',
  locations: 'Локациялар',
  locationsList: 'Локациялар тізімі',
  locationCreateTitle: 'Локацияны қосу',
  locationEditTitle: 'Локацияны өңдеу',
  locationWillBeSaved: 'Локация сақталатын болады',
  locationCreated: 'Локация қосылды',
  locationEdited: 'Локация өзгертілді',
  locationDeleted: 'Локация өшірілді',
  rates: 'Тарифтер',
  ratesList: 'Тарифтердің тізімі',
  rateDateTimeStart: 'Тариф әрекетінің басталуы',
  rateDateTimeEnd: 'Тариф әрекетінің аяқталуы',
  rateEditTitle: 'Тарифті өңдеу',
  rateViewTitle: 'Тарифті қарау',
  rateCreateTitle: 'Тарифті қосу',
  rateCopyTitle: 'Тарифті көшіру',
  rateCreated: 'Тариф қосылды',
  rateEdited: 'Тариф өзгертілді',
  rateDeleted: 'Тариф өшірілді',
  createRate: 'Тарифті қосу',
  finishTheRate: 'Тарифті аяқтау',
  serviceZone: 'Сервистік аймақ',
  serviceZones: 'Аймақтар',
  serviceZonesList: 'Аймақтар тізімі',
  withoutServiceZone: 'Сервистік аймақсыз',
  serviceZoneEditTitle: 'Сервистік аймақты өңдеу',
  serviceZoneViewTitle: 'Сервистік аймақты қарау',
  serviceZoneCreateTitle: 'Сервистік аймақты қосу',
  createServiceZone: 'Сервистік аймақты қосу',
  serviceZoneWillBeSaved: 'Сервистік аймақ сақталатын болады',
  serviceZoneCreated: 'Сервистік аймақ қосылды',
  serviceZoneEdited: 'Сервистік аймақ өзгертілді',
  serviceZoneDeleted: 'Сервистік аймақ өшірілді',
  coverages: 'Қамтулар',
  coveragesList: 'Қамтулар тізімі',
  allCoverages: 'Барлық қамтулар',
  coverageDeleted: 'Қамту өшірілді',
  coverageAdded: 'Қамту қосылды',
  coverageEdited: 'Қамту өзгертілді',
  withoutCoverages: 'Қамтусыз',
  coverageEditTitle: 'Қамтуды өңдеу',
  coverageViewTitle: 'Қамтуды қарау',
  coverageCreateTitle: 'Қамтуды қосу',
  schedules: 'Кестелер',
  scheduleEditTitle: 'Кестені өңдеу',
  scheduleViewTitle: 'Кестені қарау',
  scheduleCreateTitle: 'Кестені қосу',
  scheduleDeleted: 'Кесте өшірілді',
  scheduleCopyTitle: 'Кестені қайталау',
  sessionsRoutes: 'Ауысым маршруттары',
  timeslot: 'Уақытша слот',
  deliveryTime: 'тапсырманы орындаудың жоспарлы немесе нақты уақыты',
  role: 'Рөл',
  phone: 'Телефон',
  contractor: 'Контрагент',
  contactPerson: 'Байланысушы тұлға',
  contacts: 'Байланыстар',
  comment: 'Пікір',
  createdDateTime: 'Құрылған күні',
  creator: 'Құрылды', // Құрылды: Сидоров В.
  resolution: 'Қарар',
  courierSessionOnDate: '{{content}} ауысымы',
  courierSessionCreated: 'Жаңа ауысым бапталды',
  courierSessionEdited: 'Ауысым өзгертілді',
  courierSessionDeleted: 'Ауысым сәтті өшірілді',
  courierSessionSetup: 'Жаңа ауысымды баптау',
  courierSessionEditTitle: 'Ауысымды өңдеу',
  courierSessionCreateTitle: 'Ауысымды қосу',

  // navigation
  goBackward: 'Артқа қайту',
  goIndexPage: 'басты бетке',
  goToCourierSession: 'Ауысымға қайту',
  goToServiceZoneCreation: 'Сервистік аймақты құруға көшу',
  goToRateCreation: 'Тарифті құруға көші',
  goToCoverageCreation: 'Қамтудыы құруға көшу',
  goToOrderList: 'Тапсырыстар тізіміне',
  goToRouteList: 'Маршруттар тізіміне',
  goToCourierList: 'Курьерлер тізіміне',
  goToList: 'Тізімге қайту',

  // actions
  authorize: 'Кіру',
  save: 'Сақтау',
  calculate: 'Есептеу',
  saveAll: 'Барлығын сақтау',
  calculateAll: 'Барлығын есептеу',
  editCourier: 'Курьерді өңдеу',
  cancelTheRoute: 'Маршрутты жою',
  cancelTheRoutes: 'Маршруттарды жою',
  add: 'Қосу',
  delete: 'Өшіру',
  edit: 'Редакциялау',
  createLocation: 'Локацияны қосу',
  addTask: 'Тапсырманы қосу',
  sendForLoading: 'Жүктеп тиеуге жіберу',
  geocode: 'Геокодтау',
  create: 'Құру',
  selectCourierForRoute: 'Курьер мен көлікті таңдау',
  cleanup: 'Тазарту',
  hideInactive: 'Белсенді еместерді жасыру',
  editWarehouseSettings: 'Қойма баптауларын өзгерту',
  addDeliveryMethod: 'Жеткізу тәсілін қосу',
  removeLastDeliveryMethod: 'Соңғы жеткізу әдісін өшіру',
  beginTheSession: 'Ауысымды бастау',
  endTheSession: 'Ауысымды аяқтау',
  refresh: 'Жаңарту',
  select: 'Таңдау',
  apply: 'Қолдану',
  showCoveragesByDeliveryMethod: 'Жеткізу әдістері бойынша қаамтуды көрсету',
  applyToOtherDays: 'Аптаның басқа күндеріне қолдану',
  hidePast: 'Өтіп кеткендерді жасыру',

  // common
  yes: 'Иә',
  no: 'Жоқ',
  or: 'немесе',
  cancel: 'Болдырмау',
  noData: 'Мәліметтер жоқ',
  nothingWasFound: 'Ештеңе табылған жоқ',
  error: 'Қате',
  somethingIsWrong: 'Бір нәрсе дұрыс болмай кетті',
  areYouSure: 'Сіз сенімдісіз бе?',
  sendToCourier: 'Курьерге жібері',
  lastSession: 'Соңғы ауысым',
  saved: 'сақталды',
  oneMoreCoverage: 'Тағы кір қамту',
  addressTitle: 'Мекенжайы',
  extraTask: 'Қосымша тапсырма',
  deliveryDate: 'Жеткізу күні',
  timeSlotStart: 'Слотты басталуы',
  timeSlotEnd: 'Слотың аяқталуы',
  onAddress: 'мекен-жай бойынша',
  delivery: 'Жеткізу',
  addressesLength: 'Мекен-жайлар:\u00A0{{content}}',
  tasksLength: 'тапсырмалар:\u00A0{{content}}',
  onTheList: 'Тізіммен',
  onTheMap: 'Картада',
  loadingAtTime: '{{content}}-де жүктеу',
  comeToWarehouseAtTime: '{{content}}-де қоймаға қайту',
  sendToLoadTime: 'Жүктеп тиеуге жіберу уақыты',
  weightFullness: 'Салмақ бойынша жүктеу',
  volumeFullness: 'Көлемі бойынша жүктеу',
  filters: 'Фильтрлар',
  selected: 'Таңдалды',
  personalData: 'Жеке мәліметтер',
  authentication: 'Сәйкестендіру',
  parameters: 'Параметрлер',
  mainWarehouseSettings: 'Негізгі баптаулар',
  problem: 'Проблемалық',
  mondayShort: 'Дүй',
  tuesdayShort: 'Сейс',
  wednesdayShort: 'Сәрс',
  thursdayShort: 'Бейс',
  fridayShort: 'Жұма',
  saturdayShort: 'Сенбі',
  sundayShort: 'Жекс',
  time: 'Уақыт',
  period: 'Әрекет ету кезеңі',
  number: 'Нөмірі',
  atTime: '{{content}} де',
  fromTime: '{{content}} бастап',
  totalCount: 'Барлығы',
  sizes: 'Өлшемдер',
  height: 'Биіктігі',
  heightWithUnit: 'Биіктігі, м.',
  heightTemplate: 'Б: {{content}} м.',
  width: 'Ені',
  widthWithUnit: 'Ені, м.',
  widthTemplate: 'Е: {{content}} м.',
  length: 'Ұзындығы',
  lengthWithUnit: 'Ұзындығы, м.',
  lengthTemplate: 'Ұ: {{content}} м.',
  type: 'Типі',
  automatic: 'автоматты түрде',
  changedMyMind: 'Айнып қалдым',
  priceUnitRub: 'руб.',
  averageSpeedUnitKmPerHour: 'км/сағ',
  timeUnitDayShort: 'к.',
  timeUnitHourShort: 'с.',
  timeUnitMinuteShort: 'мин.',
  weightUnit: 'кг',
  lengthUnit: 'м',
  forDate: '{{content}} ге',
  byOrder: 'тапсырыс бойынша',
  search: 'Іздеу',
  searchByTitle: 'Атауы бойынша іздеу',
  searchByLocation: 'Локациялар бойынша іздеу',
  searchByNumber: 'Нөмірі бойынша іздеу',
  searchByTitleOrNumber: 'Атауы немесе мемлекеттік нөмірі бойынша іздеу',
  searchByFullNameEmailPhone: 'Аты-жөні, email немесе телефон арқылы іздеу',
  searchByTitleAddressPhone: 'Аты, мекен-жайы немесе телефоны бойынша іздеу',

  // form fields
  date: 'Күні',
  from: 'Басталуы',
  to: 'Соңы',
  disabled: 'белсенді емес',
  activity: 'Белсенділік',
  title: 'Атауы',
  id: 'Сәйкестендіргіш',
  externalId: 'Сыртқы жүйедегі сәйкестендіргіш',
  isActivated: 'Белсендірілген',
  isActive: 'Белсенді',
  login: 'Логин',
  password: 'Құпия сөз',
  passwordConfirmation: 'Құпия сөзді растау',
  sessionPlannedDate: 'Ауысымның басталу күні',
  fullName: 'Аты-жөні',
  coverageCreation: 'Қамтуды құру',
  newCoverage: 'Жаңа қамту',
  polygon: 'Полигон',
  autoDrawPolygon: 'Полигонды салу',
  autoDraw: 'Салу',
  selectAll: 'Барлығын таңдау',
  parentLocation: 'Ата-ана локациясы',
  positionOnTheMap: 'Картадағы орны',
  destination: 'Тағайындалу орны',
  sender: 'Жөнелтуші',
  recipient: 'Алушы',
  plannedDateTime: 'Күтілетін жеткізу уақыты',
  taskCancelationReason: 'Тапсырманы жою себебі',
  groupTasksByCoverage: 'Қамту бойынша сұрыптау',
  dayStart: 'Күн бастауы',
  dayEnd: 'Күннің аяқталуы',
  interval: 'Аралық',
  setUpEqualIntervals: 'Тең аралықтарды баптау',
  orderLimit: 'Тапсырыстар лимиті',
  price: 'Құны',
  averageSpeed: 'Орташа жылдамдығы',
  addNewRow: 'Жол қосу',
  shopShortName: 'Қысқаша атауы',
  shopLegalName: 'Заңды атауы',
  shopCurrency: 'Валюта',
  volumeWithUnit: 'Көлемі, куб.м.',
  loadingTime: 'Жүктеп тиеу уақыты',
  surname: 'Тегі',
  name: 'Аты',
  patronymic: 'Әкесінің аты',
  userStatus: 'Қолданушы мәртебесі',
  latitude: 'Ендігі',
  longitude: 'Бойлығы',
  timezone: 'Тайм аймақ',
  warehouseGroupId: 'Қоймалар тобының сәйкестендіргіші',
  maxRouteTime: 'Маршруттың максималды уақыты',
  maxCourierDowntime: 'Курьердің тұрып қалуының максималды уақыты',
  maxDelayTime: 'Максималды кешігу уақыты',
  maxLeadTime: 'Максималды озу уақыты',
  unloadingTime: 'Жүк түсіру уақыты',
  guardLimit: 'Қорғаныс аралығы',
  fullDayRoutes: 'Күн бойына маршруттармен толтыру',
  weight: 'Салмағы',
  volume: 'Көлемі',
  sum: 'Сомасы',
  enterTitle: 'Атауын енгізіңіз',
  enterId: 'Сәйкестендіргішті енгізіңіз',
  enterLogin: 'Логинді енгізіңіз',
  enterPassword: 'Құпия сөзді енгізіңіз',
  enterPasswordConfirmation: 'Құпия сөзді растауды енгізіңіз',
  enterTransport: 'Транспорт таңдаңыз',
  enterDate: 'Күнді таңдаңыз',
  enterUserName: 'Қолданушы атын енгізіңіз',
  enterPolygon: 'Картадан қамту алып жатқан аймақты белгілеңіз',
  enterLocationName: 'Локацияны енгізіңіз',
  enterLocation: 'Локацияны таңдаңыз',
  enterWarehouse: 'Қойманы таңдаңыз',
  enterWarehouses: 'Қоймаларды таңдаңыз',
  enterLocationCenter: 'Масштабты таңдаңыз және локация ортасына маркерді орнатыңыз',
  enterSearchQueryAddress: 'Іздеу үшін мекен-жайды енгізіңіз',
  enterTaskType: 'Тапсырма типін таңдаңыз',
  enterDestination: 'Тағайындалу орнын таңдаңыз',
  enterTime: 'Уақытты таңдаңыз',
  enterCourier: 'Курьерді таңдаңыз',
  enterHeight: 'Биіктігін таңдаңыз',
  enterWidth: 'Енін таңдаңыз',
  enterLength: 'Ұзындығын таңдаңыз',
  enterTaskCancelationReason: 'Себебін таңдаңыз',
  enterServiceZone: 'Сервистік аймақты таңдаңыз',
  enterDeliveryMethod: 'Жеткізу әдісін таңдаңыз',
  enterCoverages: 'Қамтуды таңдаңыз',
  enterDayStart: 'Күн басталуын таңдаңыз',
  enterDayEnd: 'Күн соңын көрсетіңіз',
  enterInterval: 'Аралықты көрсетіңіз',
  enterOrderLimit: 'Лимитті көрсетіңіз',
  enterPrice: 'Құнын көрсетіңіз',
  enterAverageSpeed: 'Жылдамдықты көрсетіңіз',
  enterShopShortName: 'Қысқаша атауын енгізіңіз',
  enterShopLegalName: 'Заңды атауын енгізіңіз',
  enterTransportNumber: 'Мемлекеттік нөмірді енгізіңіз',
  enterTransportWeight: 'Жүк көтерімділікті енгізіңіз',
  enterLoadingTime: 'Уақытты енгізіңіз',
  enterSurname: 'Тегіңізді енгізіңіз',
  enterName: 'Атыңызды енгізіңіз',
  enterPatronymic: 'Әкеңіздің атын енгізіңіз',
  enterEmail: 'Email енгізіңіз',
  enterPhone: 'Телефонды енгізіңіз',
  enterRole: 'Рөлді таңдаңыз',
  enterAddress: 'Мекен-жайды енгізіңіз',
  enterLatitude: 'Ендікті енгізіңіз',
  enterLongitude: 'Бойлықты енгізіңіз',
  enterPoint: 'Картадан нүктені белгілеңіз',
  enterTimezone: 'Тайм аймақты таңдаңыз',
  enterWarehouseGroupId: 'Қоймалар тобының сәйкестендіргішін енгізіңіз',
  enterShops: 'Дүкендерді таңдаңыз',
  enterMaxRouteTime: 'Маршрут уақытын енгізіңіз',
  enterMaxDelayTime: 'Кешігу уақытын енгізіңіз',
  enterMaxCourierDowntime: 'Курьердің тұрып қалу уақытын енгізіңіз',
  enterMaxLeadTime: 'Озу уақытын енгізіңіз',
  enterUnloadingTime: 'Жүкті түсіру уақытын енгізіңіз',
  enterGuardLimit: 'Қорғаныс аралығын енгізіңіз',
  enterWeight: 'Салмақты енгізіңіз',
  enterVolume: 'Көлемін енгізіңіз',

  // other
  possiblyLate: 'Кешігуі мүмкін',
  latenessTitle: 'Кешігу',
  lateness_one: 'кешігу',
  lateness_few: 'кешігу',
  lateness_many: 'кешігу',
  lateness_other: 'кешігу',
  possiblyOutrunning: 'Ерте келуі мүмкін',
  outrunningTitle: 'Озу',
  outrunning_one: 'озу',
  outrunning_few: 'озу',
  outrunning_many: 'озу',
  outrunning_other: 'озу',
  task_one: 'тапсырма',
  task_few: 'тапсырмалар',
  task_many: 'тапсырма',
  task_other: 'тапсырма',
  coverage_one: 'қамту',
  coverage_few: 'қамтулар',
  coverage_many: 'қамту',
  coverage_other: 'қамту',
  address_one: 'мекенжайы',
  address_few: 'мекен-жайлар',
  address_many: 'мекен-жай',
  address_other: 'мекен-жай',

  // validators
  passwordRequiredValidator: 'Өтінеміз, құпия сөзді көрсетіңіз',
  loginRequiredValidator: 'Өтінеміз, логинді көрсетіңіз',
  transportRequiredValidator: 'Көлік құралын таңдаңыз',
  requiredValidator: 'Бұл өрісті міндетті түрде толтыру қажет',
  moreThanNullNumberValidator: 'Мән нөлден үлкен болуы керек',
  positiveNumberValidator: 'Мән оң болу керек',
  integerNumberValidator: 'Мән тұтас болуы керек',
  compareWithPasswordValidator: 'Құпия сөздер сәйкес келуі қажет',
  moreThanNullZoomValidator: 'Масштаб нөлден көп болуы қажет',
  minIntervalValidator: 'Минималды рұқсат етілген интервал - 5 минут',
  maxIntervalValidator: 'Максималды рұқсат етілген интервал-1 күн',
  orderLimitValidator: 'Тапсырыс шегі 0-ден кем болмауы керек',
  priceValidator: 'Құны 0-ден кем болмауы керек',
  surnameValidator: 'Тегіңізді енгізіңіз',
  nameValidator: 'Атыңызды енгіңізің',
  roleValidator: 'Рөлді таңдаңыз',
  scheduleRequiredValidator: 'Кестені қосу қажет',

  // confirm
  defaultConfirm: 'Сіз {{content}} алғыңыз келетініне сенімдісіз',
  deliveryMethodDeleteConfirm: 'Жеткізу әдісін өшіру қажет пе?',
  clientGroupDeleteConfirm: 'Клиенттер тобын өшіру қажет пе?',
  warehouseDeleteConfirm: 'Қойманы өшіру қажет пе?',
  shopDeleteConfirm: 'Дүкенді өшіру қажет пе?',
  transportDeleteConfirm: 'Көлікті өшіру қажет пе?',
  finishTheRateConfirm: 'Тарифті аяқтау қажет пе? Тариф 1 минуттан кейін әрекет етуді тоқтатады.',
  leaveThePageConfirm: 'Беттен шығып кету керек екеніне сенімдісіз бе?',
  timeSlotDeleteConfirm: 'Уақытша слотты өшіру қажет пе?',
  courierDeleteConfirm: 'Курьерді өшіру қажет пе?',
  userDeleteConfirm: 'Қолданушыны өшіру қажет пе?',
  locationDeleteConfirm: 'Локацияны өшіру қажет пе?',
  rateDeleteConfirm: 'Тарифті өшіру қажет пе?',
  serviceZoneDeleteConfirm: 'Сервистік аймақты өшіру қажет пе?',
  coverageDeleteConfirm: 'Қамтуды өшіру қажет пе?',
  coverageDelete: 'Қамтуды өшіру қажет пе',
  coverageDeleteFromListConfirm: 'Тізімдегі қамтуды өшіру қажет пе?',
  endCourierSessionConfirm:
    'Сіз шынымен де ауысымды аяқтағыңыз келе ме? Барлық аяқталмаған маршруттар жойылады.',

  // notifications & phrases
  addressIsNotDefined: 'Мекен-жай анықталмаған',
  cannotCallTheClient: 'Клиент курьердің қоңырауына жауап бермеді',
  courierIsNotSelected: 'Курьер мен көлік таңдалған жоқ',
  sessionWillBeCreateAutomatically: 'Ауысым автоматты түрде құрылатын болады',
  transportIsNotCompareToWarehouse: 'Қойма автокөлікке қолжетімдімен сәйкес келмейді',
  courierIsNotCompareToWarehouse: 'Қойма курьерге қолжетімдімен сәйкес келмейді',
  transportIsNotCompareToDeliveryMethod: 'Жеткізу әдісі автокөлікке қолжетімдімен сәйкес келмейді',
  thereIsActiveRoutesInSession: 'Ауысымда аяқталмаған маршруттар бар',
  locationHasNoRates: 'Локацияда бірде-бір тариф жоқ',
  letsCreateRoute: 'Маршрутты құру үшін осы жерге тапсырмаларды алып келіңіз.',
  thereAreNoZones: 'Бұл жерде бірде-бір аймақ жоқ',
  thereAreSomeProblems: 'Проблемалар анықталды',
  noSectionFound: 'Бөлім жоқ',
  accessDenied: 'Қолжетімділікке тыйым салынған',
  noTasks: 'Тапсырмалар жоқ',
  notDefined: 'Анықталмаған',
  thereAreTasksWithNotAvailableDeliveryMethodsForTransport:
    'Автокөлік бармайды: жеткізудің қол жетімді емес әдісі бар тапсырмалар бар',
  thereAreTasksWithNotAvailableWarehouseForTransport:
    'Автокөлік бармайды: қол жетімді емес қоймасы бар тапсырмалар бар',
  thereAreTasksWithNotAvailableWarehouseForCourier:
    'Курьер бармайды: қол жетімді емес қоймасы бар тапсырмалар бар',
  letsCreateCourierSession:
    'Осы күнге курьер үшін ауысымды жоспарлау үшін "Ауысымды қосу" батырмасын басыңыз',
  warehouseCreatedWithNoCoords:
    'Қойма геокодтау қатесімен құрылған. Белсенді ету үшін қойма мекен-жайын көрсетіңіз',
  maxDelayTimeMeaning:
    'Егер 0 мәні орнатылса, онда кешігуге тыйым салынады. Егер жол толтырылмаса, онда шектеу жоқ',
  maxLeadTimeMeaning:
    'Егер 0 мәні орнатылса, онда озуға тыйым салынады. Егер жол толтырылмаса, онда шектеу жоқ',
  fullDayRoutesMeaning: 'Тапсырыстарды күні бойына бөлу',
  searchByFullName: 'Аты-жөні бойынша іздеу',
  defaultSort: 'Әдепкі сұрыптау',
  ascendingAmountSort: 'Өсуі бойынша сомасы',
  descendingAmountSort: 'Кемуі бойынша сомасы',
  ascendingWeightSort: 'Салмақ артуы бойынша',
  descendingWeightSort: 'Салмақ кемуі бойынша',
  ascendingVolumeSort: 'Көлем өсуі бойынша',
  descendingVolumeSort: 'Көлем кемуі бойынша',
  couriersSuccessfullySend: 'Курьерлер тиеуге жіберілді',
  courierHasProcessingRoute: 'Курьерде орындалатын маршрут бар',
  courierHasProcessingSession: 'Курьерде орындалатын ауысым бар',
  readyToSendCouriers: 'Жіберуге дайын курьерлер',
  noCouriersToSend: 'Жіберуге дайын бірде-бір курьер жоқ',
  addExtraTask: 'Қосымша тапсырма құру',
  taskCreationReason: 'Себебін сипаттаңыз',
  extraTaskPreRequest: 'Курьер клиентпен кездесті ме?',
  extraTaskReasons: 'Қажет болса, қосымша тапсырма жасалады:',
  extraTaskReason1: 'Қайтаруды рәсімдеу',
  extraTaskReason2: 'Тауарларды жеткізу',
  extraTaskReason3: 'Тауарларды алмастыру',
  extraTaskWarning: 'Мұндай тапсырма Есептік жүйеде көрсетілмейді.',
  extraTaskAction: 'Егер себеп басқаша болса, Техникалық қолдауға жүгініңіз',
};
