import './style.less';
import 'leaflet/dist/leaflet.css';
import 'leaflet-geosearch/assets/css/leaflet.css';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';

import React, { useState } from 'react';

import L, { LatLngExpression } from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { observer } from 'mobx-react-lite';
import { LayersControl, MapContainer, TileLayer, useMapEvents } from 'react-leaflet';

import { IConfigTile } from 'helpers/config';
import { CoreStore } from 'modules/core/stores/CoreStore';
import Search from 'modules/map/containers/Search';
import SearchResult from 'modules/map/containers/Search/SearchResult';
import ViewPort from 'modules/map/containers/ViewPort';
import { getTileLayer, setTileLayer } from 'modules/map/helpers';
import { useStore } from 'services/store';

const DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconAnchor: [13, 41],
});

L.Marker.prototype.options.icon = DefaultIcon;

interface IProps {
  zoom?: number;
  center?: LatLngExpression;
  isSearch?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mapView?: any;
  whenCreated?: (map: L.Map) => void;
  entityID?: number | string;
  children?: React.ReactNode;
}

interface IPropsLayerControl {
  tileView: IConfigTile;
  core: CoreStore;
}

const LayerControlWithEventHandlers = ({ tileView, core }: IPropsLayerControl) => {
  useMapEvents({
    baselayerchange: (event): void => setTileLayer(event.name),
  });

  return (
    <LayersControl position="bottomright">
      {core.config.tile.map((item, key) => (
        <LayersControl.BaseLayer name={item.name} key={key} checked={item.name === tileView.name}>
          <TileLayer
            attribution={item.params.attribution}
            url={item.url}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            subdomains={item.name === '2gis' ? [0, 1, 2, 3] : ['a', 'b', 'c']}
          />
        </LayersControl.BaseLayer>
      ))}
    </LayersControl>
  );
};

const Map = ({
  zoom,
  center,
  children,
  isSearch = true,
  mapView,
  whenCreated,
  entityID,
  ...rest
}: IProps) => {
  const { core } = useStore();

  const tileView = getTileLayer(core.config.tile);

  const [searchResult, setSearchResult] = useState(null);

  const onResult = (result) => {
    setSearchResult(result);
  };

  return (
    <MapContainer
      center={center || [0, 0]}
      zoom={zoom || 2}
      maxZoom={18}
      minZoom={2}
      doubleClickZoom={false}
      attributionControl={false}
      style={{ width: '100%', height: '100%' }}
      {...rest}
    >
      <ViewPort
        mapView={mapView || []}
        whenCreated={whenCreated}
        entityID={entityID ? entityID : 'default'}
      />
      <LayerControlWithEventHandlers tileView={tileView} core={core} />
      {isSearch ? (
        <>
          <Search geocoderConfig={core.config.geocoderBMP} onResult={onResult} />
          {searchResult && <SearchResult result={searchResult} />}
        </>
      ) : null}
      {children}
    </MapContainer>
  );
};

export default observer(Map);
