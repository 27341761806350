import React, { ReactNode, forwardRef, useEffect } from 'react';

import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { observer } from 'mobx-react-lite';
import { BaseSelectRef } from 'rc-select/lib/BaseSelect';

import { selectFilter } from 'helpers/form';
import { DEFAULT_SELECT_PROPS } from 'modules/common/components/Select';
import { IDeliveryMethods } from 'modules/delivery-methods/models/types';
import { translate } from 'modules/localization';
import { useStore } from 'services/store';

interface IProps {
  isSelectAllOptionAllowed?: boolean;
  isMissingGeo?: boolean;
  disabledGuids?: string[];
}

const DeliveryMethodsSelect = forwardRef<BaseSelectRef, IProps & SelectProps>(
  (
    {
      onChange,
      isSelectAllOptionAllowed,
      isMissingGeo,
      disabledGuids,
      ...rest
    }: IProps & SelectProps,
    ref,
  ) => {
    const { deliveryMethods } = useStore();

    const getOptions = () => {
      const options = deliveryMethods.list;
      if (options && Array.isArray(options)) {
        return options;
      }
      return [];
    };

    const fetchDefaultOptions = (): void => {
      deliveryMethods.getList({ pageSize: 100, current: 1 }, false);
    };

    useEffect(() => {
      fetchDefaultOptions();
    }, []);

    const onChangeValue = (value, option): void => {
      const isAll = !value || (value && value.length && value.includes('all'));
      let valueToSet;
      if (rest?.mode === 'multiple') {
        valueToSet = isAll ? getOptions().map((o) => o.guid) : value;
      } else {
        valueToSet = isSelectAllOptionAllowed && isAll ? 'all' : value;
      }
      onChange(valueToSet, option);
    };

    const renderOption = (option: IDeliveryMethods): ReactNode => {
      if (option) {
        return (
          <Select.Option
            key={option.guid}
            value={option.guid}
            disabled={disabledGuids ? disabledGuids.includes(option.guid) : null}
            label={option.name}
          >
            {option.name || option.guid} {option.disabled ? ` (${translate('disabled')})` : ''}
          </Select.Option>
        );
      }
      return null;
    };

    const options = getOptions();

    return (
      <Select
        showSearch
        allowClear
        ref={ref}
        loading={deliveryMethods.isLoadingList}
        defaultActiveFirstOption={false}
        filterOption={selectFilter}
        getPopupContainer={(el) => el}
        onChange={onChangeValue}
        placeholder={translate('enterDeliveryMethod')}
        {...DEFAULT_SELECT_PROPS}
        {...rest}
      >
        {isSelectAllOptionAllowed && options.length > 0 ? (
          <Select.Option key="all" value="all" data-testid="deliveryMethodsInput-selectAll">
            {translate('allDeliveryMethod')}
          </Select.Option>
        ) : null}
        {options.map(renderOption)}
        {isMissingGeo ? (
          <>
            <Select.Option key="missing_tariff" value="missing_tariff">
              {translate('withoutDeliveryMethod')}
            </Select.Option>
            <Select.Option key="missing_service_zone" value="missing_service_zone">
              {translate('withoutServiceZone')}
            </Select.Option>
          </>
        ) : null}
      </Select>
    );
  },
);

DeliveryMethodsSelect.displayName = 'DeliveryMethodsSelect';

export default observer(DeliveryMethodsSelect);
