export const az_translations: Record<string, string> = {
  // sections & entities
  planning: 'Planlaşdırma',
  tariffing: 'Tariflər',
  directory: 'Məlumat',
  logout: 'Çıxış',
  order: 'Sifariş',
  orders: 'Sifarişlər',
  ordersList: 'Sifarişlərin siyahısı',
  orderStatus: 'Sifarişin statusu',
  orderDeliveryDateTime: 'Gözlənilən vaxt',
  orderSizes: 'Yükün parametrləri',
  orderType: 'Növü',
  orderDetails: 'Sifarişin detalları',
  taskTitle: 'Tapşırıq',
  tasks: 'Tapşırıqlar',
  tasksList: 'Tapşırıqların siyahısı',
  taskType: 'Tapşırığın növü',
  taskStatus: 'Tapşırığın statusu',
  taskDeliveryDateTime: 'Gözlənilən vaxt',
  taskPlannedDeliveryDateTime: 'Planlaşdırılan vaxt',
  taskRealDeliveryDateTime: 'Faktiki vaxt',
  taskSizes: 'Yükün parametrləri',
  removeTaskFromRoute: 'Marşrutdan çıxar',
  cancelTheTask: 'Tapşırığı ləğv et',
  taskCancelation: 'Tapşırığın ləğv edilməsi',
  finishTheTask: 'Başa çatdır',
  route: 'Marşrut',
  routes: 'Marşrutlar',
  routesList: 'Marşrutların siyahısı',
  routeStatus: 'Marşrutun statusu',
  routeStartDate: 'Başlanma tarixi',
  routeGenerator: 'Marşrutların generatoru',
  routePlannedDateTime: 'Planlaşdırılan vaxt',
  routeRealDateTime: 'Faktiki vaxt',
  routeCreated: 'Marşrut yaradıldı',
  routeFormTitle: 'Marşrutun formalaşdırılması',
  routeCreateTitle: 'Marşrutun yaradılması',
  routeCalculated: 'Marşrut hesablandı',
  routeDelete: 'Marşrutu sil',
  addTasksToRoute: 'Marşruta əlavə et',
  courier: 'Kuryer',
  couriers: 'Kuryerlər',
  couriersList: 'Kuryerlərin siyahısı',
  courierEditSuccess: 'Kuryer uğurla dəyişdirildi',
  courierCreateSuccess: 'Kuryer uğurla yaradıldı',
  courierEditTitle: 'Kuryerin dəyişdirilməsi',
  courierCreateTitle: 'Kuryerin əlavə edilməsi',
  deliveryMethod: 'Çatdırılma üsulu',
  deliveryMethods: 'Çatdırılma üsulları',
  allDeliveryMethod: 'Bütün çatdırılma üsulları',
  withoutDeliveryMethod: 'Çatdırılmasız',
  deliveryMethodEditSuccess: 'Çatdırılma üsulu uğurla dəyişdirildi',
  deliveryMethodCreateSuccess: 'Çatdırılma üsulu uğurla yaradıldı',
  deliveryMethodEditTitle: 'Çatdırılma üsulunun dəyişdirilməsi',
  deliveryMethodCreateTitle: 'Çatdırılma üsulunun əlavə edilməsi',
  deliveryMethodsParams: 'Çatdırılma üsullarının parametrləri',
  deliveryMethodTitle: 'Çatdırılma üsulunun adı',
  deliveryMethodCode: 'Çatdırılma üsulunun kodu',
  clientGroup: 'Müştəri qrupu',
  clientGroups: 'Müştərilər qrupu',
  clientGroupEditSuccess: 'Müştərilər qrupu uğurla dəyişdirildi',
  clientGroupCreateSuccess: 'Müştərilər qrupu uğurla yaradıldı',
  clientGroupEditTitle: 'Müştərilər qrupunun dəyişdirilməsi',
  clientGroupCreateTitle: 'Müştərilər qrupunun əlavə edilməsi',
  warehouse: 'Anbar',
  warehouses: 'Anbarlar',
  warehouseEdited: 'Anbar uğurla dəyişdirildi',
  warehouseCreated: 'Anbar uğurla yaradıldı',
  warehouseEditTitle: 'Anbarın dəyişdirilməsi',
  warehouseCreateTitle: 'Anbarın əlavə edilməsi',
  warehouseHasNoCoords: 'Anbarın koordinatları yoxdur',
  warehouseSettingsEdited: 'Anbarın ayarları uğurla dəyişdirildi',
  client: 'Müştəri',
  shop: 'Mağaza',
  shops: 'Mağazalar',
  shopEditSuccess: 'Mağaza uğurla dəyişdirildi',
  shopCreateSuccess: 'Mağaza uğurla yaradıldı',
  shopEditTitle: 'Mağazanın dəyişdirilməsi',
  shopcCreateTitle: 'Mağazanın əlavə edilməsi',
  status: 'Status',
  transport: 'Nəqliyyat',
  transportNumber: 'Dövlət nişanı',
  transportWeightWithUnit: 'Yükqaldırma qabiliyyəti, kq',
  transportEditSuccess: 'Nəqliyyat uğurla dəyişdirildi',
  transportCreateSuccess: 'Nəqliyyat uğurla yaradıldı',
  transportEditTitle: 'Nəqliyyatın dəyişdirilməsi',
  transportCreateTitle: 'Nəqliyyatın əlavə edilməsi',
  user: 'İstifadəçi',
  users: 'İstifadəçilər',
  userEdited: 'İstifadəçi dəyişdirildi',
  userCreated: 'İstifadəçi yaradıldı',
  userEditTitle: 'İstifadəçinin dəyişdirilməsi',
  userCreateTitle: 'İstifadəçinin əlavə edilməsi',
  privacy: 'Gizlilik siyasəti',
  locations: 'Yerlər',
  locationsList: 'Yerlərin siyahısı',
  locationCreateTitle: 'Yerin əlavə edilməsi',
  locationEditTitle: 'Yerin dəyişdirilməsi',
  locationWillBeSaved: 'Yer yaddaşda saxlandı',
  locationCreated: 'Yer əlavə edildi',
  locationEdited: 'Yer dəyişdirildi',
  locationDeleted: 'Yer silindi',
  rates: 'Tariflər',
  ratesList: 'Tariflərin siyahısı',
  rateDateTimeStart: 'Tarifin işləməsinin başlanması',
  rateDateTimeEnd: 'Tarifin işlənməsinin başa çatdırılması',
  rateEditTitle: 'Tarifin dəyişdirilməsi',
  rateViewTitle: 'Tarifin baxılması',
  rateCreateTitle: 'Tarifin əlavə edilməsi',
  rateCopyTitle: 'Tarifin kopiyalanması',
  rateCreated: 'Tarif əlavə edildi',
  rateEdited: 'Tarif dəyişdirildi',
  rateDeleted: 'Tarif silindi',
  createRate: 'Tarif əlavə et',
  finishTheRate: 'Tarifi başa çatdır',
  serviceZone: 'Servis zonası',
  serviceZones: 'Zonalar',
  serviceZonesList: 'Zonaların siyahısı',
  withoutServiceZone: 'Servis zonasız',
  serviceZoneEditTitle: 'Servis zonasının dəyişdirilməsi',
  serviceZoneViewTitle: 'Servis zonasının baxılması',
  serviceZoneCreateTitle: 'Servis zonasının əlavə edilməsi',
  createServiceZone: 'Serviz zonasını əlavə et',
  serviceZoneWillBeSaved: 'Servis zonası yaddaşda saxlanacaq',
  serviceZoneCreated: 'Servis zonası əlavə edildi',
  serviceZoneEdited: 'Servis zonası dəyişdirildi',
  serviceZoneDeleted: 'Servis zonası silindi',
  coverages: 'Əhatələr',
  coveragesList: 'Əhatələrin siyahısı',
  allCoverages: 'Bütün əhatələr',
  coverageDeleted: 'Əhatə silindi',
  coverageAdded: 'Əhatə əlavə edildi',
  coverageEdited: 'Əhatə dəyişdirildi',
  withoutCoverages: 'Əhatəsiz',
  coverageEditTitle: 'Əhatənin dəyişdirilməsi',
  coverageViewTitle: 'Əhatənin baxılması',
  coverageCreateTitle: 'Əhatənin əlavə edilməsi',
  schedules: 'Cədvəllər',
  scheduleEditTitle: 'Cədvəlin dəyişdirilməsi',
  scheduleViewTitle: 'Cədvəlin baxılması',
  scheduleCreateTitle: 'Cədvəlin əlavə edilməsi',
  scheduleDeleted: 'Cədvəl silindi',
  scheduleCopyTitle: 'Cədvəli təkrar et',
  sessionsRoutes: 'Növbənin marşrutları',
  timeslot: 'Vaxt slotu',
  deliveryTime: 'tapşırığın yerinə yetirilməsinin planlaşdırılan ya da faktiki vaxtı',
  role: 'Rol',
  phone: 'Telefon',
  contractor: 'Kontragent',
  contactPerson: 'Əlaqə üçün şəxs',
  contacts: 'Əlaqə',
  comment: 'Yorum',
  createdDateTime: 'Yaradılma tarixi',
  creator: 'Yaradan', // Yaradan: V. Sidorov
  resolution: 'Əmr',
  courierSessionOnDate: '{{content}} tarixli növbə',
  courierSessionCreated: 'Yeni növbə ayarlandı',
  courierSessionEdited: 'Növbə dəyişdirildi',
  courierSessionDeleted: 'Növbə uğurla silindi',
  courierSessionSetup: 'Yeni növbənin ayarlanması',
  courierSessionEditTitle: 'Növbənin dəyişdirilməsi',
  courierSessionCreateTitle: 'Növbəni əlavə et',

  // navigation
  goBackward: 'Geri dön',
  goIndexPage: 'ana səhifəyə',
  goToCourierSession: 'Növbəyə dön',
  goToServiceZoneCreation: 'Servis zonasının yaradılmasına keç',
  goToRateCreation: 'Tarifin yaradılmasına keç',
  goToCoverageCreation: 'Əhatənin yaradılmasına keç',
  goToOrderList: 'Sifarişlərin siyahısına',
  goToRouteList: 'Marşrutların siyahısına',
  goToCourierList: 'Kuryerlərin siyahısına',
  goToList: 'Siyahıya dön',

  // actions
  save: 'Yaddaşda saxla',
  authorize: 'Giriş',
  calculate: 'Hesabla',
  saveAll: 'Hamısını yaddaşda saxla',
  calculateAll: 'Hamısını hesabla',
  editCourier: 'Kuryerin dəyişdirilməsi',
  cancelTheRoute: 'Marşrutu ləğv et',
  cancelTheRoutes: 'Marşrutları ləğv et',
  add: 'Əlavə et',
  delete: 'Sil',
  edit: 'Dəyişdir',
  createLocation: 'Yeri əlavə et',
  addTask: 'Tapşırığı əlavə et',
  sendForLoading: 'Yükləməyə göndər',
  geocode: 'Yeri kodlaşdır',
  create: 'Yarat',
  selectCourierForRoute: 'Kuryer və maşını seç',
  cleanup: 'Təmizlə',
  hideInactive: 'Aktiv olmayanları gizlət',
  editWarehouseSettings: 'Anbarın ayarlarını dəyişdir',
  addDeliveryMethod: 'Çatdırılma üsulunu əlavə et',
  removeLastDeliveryMethod: 'Sonuncu çatdırılma üsulunu sil',
  beginTheSession: 'Növbəyə başla',
  endTheSession: 'Növbəni başa çatdır',
  refresh: 'Yenilə',
  select: 'Seç',
  apply: 'Tətbiq et',
  showCoveragesByDeliveryMethod: 'Çatdırılma üsulları üzrə əhatəni göstər',
  applyToOtherDays: 'Həftənin başqa günlərinə tətbiq et',
  hidePast: 'Keçmişləri gizlət',

  // common
  yes: 'Hə',
  no: 'Yox',
  or: 'ya da',
  cancel: 'Ləğv et',
  noData: 'Məlumat yoxdur',
  nothingWasFound: 'Heç bir şey tapılmadı',
  error: 'Yanlışlıq',
  somethingIsWrong: 'Nəsə düz getmədi',
  areYouSure: 'Əminsiniz?',
  sendToCourier: 'Kuryerə göndər',
  lastSession: 'Sonuncu növbə',
  saved: 'yaddaşda saxlandı',
  oneMoreCoverage: 'Daha bir əhatə',
  addressTitle: 'Ünvan',
  extraTask: 'Əlavə tapşırıq',
  deliveryDate: 'Çatdırılma tarixi',
  timeSlotStart: 'Slotun başlanğıcı',
  timeSlotEnd: 'Slotun sonu',
  onAddress: 'ünvan',
  delivery: 'Çatdırılma',
  addressesLength: 'Ünvaların sayı:\u00A0{{content}}',
  tasksLength: 'tapşırıqların sayı:\u00A0{{content}}',
  onTheList: 'Siyahı',
  onTheMap: 'Xəritədə',
  loadingAtTime: 'Saat {{content}}-da yükləmə',
  comeToWarehouseAtTime: 'Saat {{content}}-da anbara qaytarılma',
  sendToLoadTime: 'Yükləməyə göndərmə vaxtı',
  weightFullness: 'Çəki ilə yükləmə',
  volumeFullness: 'Həcm ilə yükləmə',
  filters: 'Filtrlər',
  selected: 'Seçildi',
  personalData: 'Şəxsi məlumat',
  authentication: 'Autentifikasiya',
  parameters: 'Parametrlər',
  mainWarehouseSettings: 'Başlıca ayarlar',
  problem: 'Problemli olanlar',
  mondayShort: 'Bazar ertəsi',
  tuesdayShort: 'Cərşənbə axşamı',
  wednesdayShort: 'Çərşənbə',
  thursdayShort: 'Cümə axşamı',
  fridayShort: 'Cümə',
  saturdayShort: 'Şənbə',
  sundayShort: 'Bazar',
  time: 'Vaxt',
  period: 'İşləmə dönəmi',
  number: 'Nömrə',
  atTime: 'saat {{content}}-da',
  fromTime: 'saat {{content}}-dan',
  totalCount: 'Toplam',
  sizes: 'Ölçüləri',
  height: 'Hündürlüyü',
  heightWithUnit: 'Hündürlüyü, m.',
  heightTemplate: 'H: {{content}} m.',
  width: 'Eni',
  widthWithUnit: 'Eni, m.',
  widthTemplate: 'E: {{content}} m.',
  length: 'Uzunluğu',
  lengthWithUnit: 'Uzunluğu, m.',
  lengthTemplate: 'U: {{content}} m.',
  type: 'Növü',
  automatic: 'avtomatik',
  changedMyMind: 'Fikrimdən daşındım',
  priceUnitRub: 'rubl',
  averageSpeedUnitKmPerHour: 'km/s',
  timeUnitDayShort: 'gün',
  timeUnitHourShort: 'saat',
  timeUnitMinuteShort: 'dəqiqə',
  weightUnit: 'kq',
  lengthUnit: 'metr',
  forDate: '{{content}} tarixinə',
  byOrder: 'sifariş üzrə',
  search: 'Axtarış',
  searchByTitle: 'Ad ilə axtarış',
  searchByLocation: 'Yer ilə axtarış',
  searchByNumber: 'Nömrə ilə axtarış',
  searchByTitleOrNumber: 'Ad ya da dövlət nişanı ilə axtarış',
  searchByFullNameEmailPhone: 'A.S.A., email ya da telefon ilə axtarış',
  searchByTitleAddressPhone: 'Ad, ünvan ya da telefon ilə axtarış',

  // form fields
  date: 'Tarix',
  from: 'Başlanğıc',
  to: 'Son',
  disabled: 'aktiv deyil',
  activity: 'Aktivlik',
  title: 'Ad',
  id: 'İdentifikator',
  externalId: 'Xarici sistemdəki identifikator',
  isActivated: 'Aktivləşdirilmişdir',
  isActive: 'Aktivdir',
  login: 'Login',
  password: 'Şifrə',
  passwordConfirmation: 'Şifrənin təsdiqlənməsi',
  sessionPlannedDate: 'Növbənin başlanma tarixi',
  fullName: 'S.A.A.',
  coverageCreation: 'Əhatənin yaradılması',
  newCoverage: 'Yeni əhatə',
  polygon: 'Poliqon',
  autoDrawPolygon: 'Poliqonu çək',
  autoDraw: 'Çək',
  selectAll: 'Hamısını seç',
  parentLocation: 'Valideyn yeri',
  positionOnTheMap: 'Xəritədə olduğu yer',
  destination: 'Təyinat yeri',
  sender: 'Göndərən',
  recipient: 'Alan',
  plannedDateTime: 'Gözlənilən çatdırılma vaxtı',
  taskCancelationReason: 'Tapşırığın ləğv edilmə səbəbi',
  groupTasksByCoverage: 'Əhatə üzrə qruplaşdır',
  dayStart: 'Günün başlanğıcı',
  dayEnd: 'Günün sonu',
  interval: 'İnterval',
  setUpEqualIntervals: 'Eyni intervalları ayarla',
  orderLimit: 'Sifarişlərin limiti',
  price: 'Dəyər',
  averageSpeed: 'Orta sürət',
  addNewRow: 'Sətr əlavə et',
  shopShortName: 'Qısa ad',
  shopLegalName: 'Hüquqi ad',
  shopCurrency: 'Valyuta',
  volumeWithUnit: 'Həcm, kub.m.',
  loadingTime: 'Yükləmə vaxtı',
  surname: 'Soyadı',
  name: 'Adı',
  patronymic: 'Atasının adı',
  userStatus: 'İstifadəçinin statusu',
  latitude: 'Eni',
  longitude: 'Uzunluğu',
  timezone: 'Vaxt zonası',
  warehouseGroupId: 'Anbar qrupunun identifikatoru',
  maxRouteTime: 'Marşrutun maksimum vaxtı',
  maxCourierDowntime: 'Kuryerin maksimum dayanma vaxtı',
  maxDelayTime: 'Maksimum gecikmə vaxtı',
  maxLeadTime: 'Maksimum tez yerinə yetirmə vaxtı',
  unloadingTime: 'Yükü boşaltma vaxtı',
  guardLimit: 'Müdafiə intervalı',
  fullDayRoutes: 'Marşrutlarla bütün günlük doldurulma',
  weight: 'Çəkisi',
  volume: 'Həcmi',
  sum: 'Məbləğ',
  enterTitle: 'Adını yazın',
  enterId: 'İdentifikatorunu yazın',
  enterLogin: 'Logininizi yazın',
  enterPassword: 'Şifrənizi yazın',
  enterPasswordConfirmation: 'Şifrənin təsdiqləndiyini yazın',
  enterTransport: 'Nəqliyyatı seçin',
  enterDate: 'Tarixi seçin',
  enterUserName: 'İstifadəçi adını yazın',
  enterPolygon: 'Əhatə ilə tutulan bölgəni xəritədə qeyd edin',
  enterLocationName: 'Yeri yazın',
  enterLocation: 'Yeri seçin',
  enterWarehouse: 'Anbarı seçin',
  enterWarehouses: 'Anbarları seçin',
  enterLocationCenter: 'Miqyas seçərək markeri yerin ortasında quraşdırın',
  enterSearchQueryAddress: 'Axtarış üçün ünvanı yazın',
  enterTaskType: 'Tapşırığın növünü yazın',
  enterDestination: 'Təyinat yerini seçin',
  enterTime: 'Vaxtı seçin',
  enterCourier: 'Kuryeri seçin',
  enterHeight: 'Hündürlüyü seçin',
  enterWidth: 'Eni seçin',
  enterLength: 'Uzunluğu seçin',
  enterTaskCancelationReason: 'Səbəbi seçin',
  enterServiceZone: 'Servis zonasını seçin',
  enterDeliveryMethod: 'Çatdırılma üsulunu seçin',
  enterCoverages: 'Əhatəni seçin',
  enterDayStart: 'Günün başlanğıcını göstərin',
  enterDayEnd: 'Günün sonunu göstərin',
  enterInterval: 'İntervalı göstərin',
  enterOrderLimit: 'Limiti göstərin',
  enterPrice: 'Dəyəri göstərin',
  enterAverageSpeed: 'Sürəti yazın',
  enterShopShortName: 'Qısa adı yazın',
  enterShopLegalName: 'Hüquqi adı yazın',
  enterTransportNumber: 'Dövlət nişanını yazın',
  enterTransportWeight: 'Yükqaldırma qabiliyyətini yazın',
  enterLoadingTime: 'Vaxtı yazın',
  enterSurname: 'Soyadı yazın',
  enterName: 'Adı yazın',
  enterPatronymic: 'Atasının adını yazın',
  enterEmail: 'Email yazın',
  enterPhone: 'Telefonu yazın',
  enterRole: 'Rolu yazın',
  enterAddress: 'Ünvanı yazın',
  enterLatitude: 'Eni yazın',
  enterLongitude: 'Uzunluğu yazın',
  enterPoint: 'Xəritədə nöqtəni göstərin',
  enterTimezone: 'Vaxt zonasını seçin',
  enterWarehouseGroupId: 'Anbarlar qrupunun identifikatorunu yazın',
  enterShops: 'Mağazaları seçin',
  enterMaxRouteTime: 'Marşrutun vaxtını yazın',
  enterMaxDelayTime: 'Gecikmə vaxtını yazın',
  enterMaxCourierDowntime: 'Kuryerin dayanma vaxtını yazın',
  enterMaxLeadTime: 'Qabaqlama vaxtını yazın',
  enterUnloadingTime: 'Yükü boşaltma vaxtını yazın',
  enterGuardLimit: 'Müdafiə intervalını yazın',
  enterWeight: 'Çəkini yazın',
  enterVolume: 'Həcmi yazın',

  // other
  possiblyLate: 'Mümkün gecikmə',
  latenessTitle: 'Gecikmə',
  lateness_one: 'gecikmə',
  lateness_few: 'gecikmələr',
  lateness_many: 'gecikmə sayı',
  lateness_other: 'gecikmə sayı',
  possiblyOutrunning: 'Mümkün gecikmə',
  outrunningTitle: 'Qabaqlama',
  outrunning_one: 'qabaqlama',
  outrunning_few: 'qabaqlamalar',
  outrunning_many: 'qabaqlama sayı',
  outrunning_other: 'qabaqlama sayı',
  task_one: 'tapşırıq',
  task_few: 'tapşırıqlar',
  task_many: 'tapşırıq sayı',
  task_other: 'tapşırıq sayı',
  coverage_one: 'əhatə',
  coverage_few: 'əhatələr',
  coverage_many: 'əhatə sayı',
  coverage_other: 'əhatə sayı',
  address_one: 'ünvan',
  address_few: 'üvanlar',
  address_many: 'ünvan sayı',
  address_other: 'ünvan sayı',

  // validators
  passwordRequiredValidator: 'Lütfən, şifrənizi göstərin',
  loginRequiredValidator: 'Lütfən, logininizi göstərin',
  transportRequiredValidator: 'Nəqliyyat vasitəsini seçin',
  requiredValidator: 'Sətr mütləq doldurulmalıdır',
  moreThanNullNumberValidator: 'Dəyər sıfırdan yuxarı olmalıdır',
  positiveNumberValidator: 'Dəyər müsbət olmalıdır',
  integerNumberValidator: 'Dəyər tam olmalıdır',
  compareWithPasswordValidator: 'Şifrələr üst-üstə düşməlidir',
  moreThanNullZoomValidator: 'Miqyas sıfırdan yuxarı olmalıdır',
  minIntervalValidator: 'Ən az yolverilən interval - 5 dəqiqə',
  maxIntervalValidator: 'Ən çox yol verilən interval - 1 sutka',
  orderLimitValidator: 'Sifarişlərin limiti 0-dan az olmamalıdır',
  priceValidator: 'Dəyər 0-dan az olmamalıdır',
  surnameValidator: 'Lütfən, soyadınızı yazın',
  nameValidator: 'Lütfən, adınızı yazın',
  roleValidator: 'Lütfən, rolunuzu seçin',
  scheduleRequiredValidator: 'Cədvəl əlavə edilməlidir',

  // confirm
  defaultConfirm: '{{content}} istədiyinizdən əminsiniz?',
  deliveryMethodDeleteConfirm: 'Çatdırılma üsulu silinsin?',
  clientGroupDeleteConfirm: 'Müştərilər qrupu silinsin?',
  warehouseDeleteConfirm: 'Anbar silinsin?',
  shopDeleteConfirm: 'Mağaza silinsin?',
  transportDeleteConfirm: 'Nəqliyyat silinsin?',
  finishTheRateConfirm: 'Tarif başa çatdırılsın? Tarif 1 dəqiqə sonra başa çatdırılacaq.',
  leaveThePageConfirm: 'Səhifəni tərk etmək istədiyinizdən əminsiniz?',
  timeSlotDeleteConfirm: 'Vaxt slotu silinsin?',
  courierDeleteConfirm: 'Kuryer silinsin?',
  userDeleteConfirm: 'İstifadəçi silinsin?',
  locationDeleteConfirm: 'Yer silinsin?',
  rateDeleteConfirm: 'Tarif silinsin?',
  serviceZoneDeleteConfirm: 'Servis zonası silinsin?',
  coverageDeleteConfirm: 'Əhatə silinsin?',
  coverageDelete: 'Əhatə silinsin',
  coverageDeleteFromListConfirm: 'Siyahıdan əhatə silinsin?',
  endCourierSessionConfirm:
    'Növbəni doğrudan da başa çatdırmaq istəyirsiniz? Bütün başa çatdırılmayan marşrutlar ləğv ediləcək.',

  // notifications & phrases
  addressIsNotDefined: 'Ünvan müəyyən edilmədi',
  cannotCallTheClient: 'Kuryer müştəri ilə bağlantı yarada bilmədi',
  courierIsNotSelected: 'Kuryer və maşın seçilmədi',
  sessionWillBeCreateAutomatically: 'Növbə avtomatik olaraq yaradılacaq',
  transportIsNotCompareToWarehouse: 'Anbar əlçatan avtomobil ilə üst-üstə düşmür',
  courierIsNotCompareToWarehouse: 'Anbar əlçatan kuryer ilə üst-üstə düşmür',
  transportIsNotCompareToDeliveryMethod: 'Çatdırılma üsulu əlçatan avtomobil ilə üst-üstə düşmür',
  thereIsActiveRoutesInSession: 'Növbədə başa çatdırılmamış marşrutlar var',
  locationHasNoRates: 'Yerin heç bir tarifi yoxdur',
  letsCreateRoute: 'Marşrut qurmaq üçün tapşırıqları bura sürüşdürün.',
  thereAreNoZones: 'Burada heç bir zona yoxdur',
  thereAreSomeProblems: 'Problem aşkarlandı',
  noSectionFound: 'Bölüm yoxdur',
  accessDenied: 'Giriş qadağandır',
  noTasks: 'Tapşırıq yoxdur',
  notDefined: 'Müəyyən edilmədi',
  thereAreTasksWithNotAvailableDeliveryMethodsForTransport:
    'Avtomobil yola düşməyəcək - əlçatmayan çatdırılma üsulu ilə tapşırıqlar var',
  thereAreTasksWithNotAvailableWarehouseForTransport:
    'Avtomobil yola düşməyəcək - əlçatmayan anbar ilə tapşırıqlar var',
  thereAreTasksWithNotAvailableWarehouseForCourier:
    'Kuryer yola düşməyəcək - əlçatmayan anbar ilə tapşırıqlar var',
  letsCreateCourierSession:
    'Kuryer üçün bu günlük növbəni planlaşdırmaq üçün "Növbə əlavə et" basın',
  warehouseCreatedWithNoCoords:
    'Anbar yer kodlaşma yanlışlığı ilə yaradılmışdır. Aktivləşdirmə üçün anbarın ünvanını dəqiqləşdirin',
  maxDelayTimeMeaning:
    '0 dəyəri quraşdırılmışsa, gecikmə qadağandır. Sətr doldurulmamışsa, məhdudiyyət yoxdur',
  maxLeadTimeMeaning:
    '0 dəyəri quraşdırılmışsa, qabaqlama qadağandır. Sətr doldurulmamışsa, məhdudiyyət yoxdur',
  fullDayRoutesMeaning: 'Sifarişləri bütün günə bölüşdür',
  searchByFullName: 'A.S.A. ilə axtarış',
  defaultSort: 'Ayarlarda olduğu kimi çeşidləmə',
  ascendingAmountSort: 'Çoxalmağa doğru məbləğ',
  descendingAmountSort: 'Azalmağa doğru məbləğ',
  ascendingWeightSort: 'Çoxalmağa doğru çəki',
  descendingWeightSort: 'Azalmağa doğru çəki',
  ascendingVolumeSort: 'Çoxalmağa doğru həcm',
  descendingVolumeSort: 'Azalmağa doğru həcm',
  couriersSuccessfullySend: 'Kuryerlər yükləməyə göndərilmişdir',
  courierHasProcessingRoute: 'Kuryerin bir yerinə yetirilməmiş marşrutu var',
  courierHasProcessingSession: 'Kuryerin yerinə yetirilməkdə olan növbəsi var',
  readyToSendCouriers: 'Göndərilməyə hazır olan kuryerlər',
  noCouriersToSend: 'Göndərilməyə hazır olan kuryer yoxdur',
  addExtraTask: 'Əlavə tapşırıq yarat',
  taskCreationReason: 'Səbəbi təsvir edin',
  extraTaskPreRequest: 'Kuryer müştəri ilə görüşdümü?',
  extraTaskReasons: 'Bunlar lazımdırsa, əlavə tapşırıq yaradılır:',
  extraTaskReason1: 'Geri qaytarmaq',
  extraTaskReason2: 'Malı çatdırmaq',
  extraTaskReason3: 'Malı dəyişdirmək',
  extraTaskWarning: 'Bu kimi tapşırıq Qeydiyyat sistemində göstərilməyəcəkdir.',
  extraTaskAction: 'Səbəbi başqadırsa, Texniki dəstəyə müraciət edin',
};
