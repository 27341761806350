export const ky_translations: Record<string, string> = {
  // sections & entities
  planning: 'Мерчемдөө',
  tariffing: 'Тарификация',
  directory: 'Маалымдама',
  logout: 'Чыгуу',
  order: 'Буйрутма',
  orders: 'Буйрутмалар',
  ordersList: 'Буйрутмалардын тизмеси',
  orderStatus: 'Буйрутманын статусу',
  orderDeliveryDateTime: 'Күтүлүүчү убакыт',
  orderSizes: 'Жүктүн параметрлери',
  orderType: 'Түрү',
  orderDetails: 'Буйрутманын деталдары',
  taskTitle: 'Тапшырма',
  tasks: 'Тапшырмалар',
  tasksList: 'Тапшырмалар тизмеси',
  taskType: 'Тапшырмалар түрү',
  taskStatus: 'Тапшырмалардын статусу',
  taskDeliveryDateTime: 'Күтүлүүчү убакыт',
  taskPlannedDeliveryDateTime: 'Мерчемделген убакыт',
  taskRealDeliveryDateTime: 'Чыныгы убакыт',
  taskSizes: 'Жүктүн параметрлери',
  removeTaskFromRoute: 'Маршруттан алып салуу',
  cancelTheTask: 'Тапшырманы жокко чыгаруу',
  taskCancelation: 'Тапшырмаларды жокко чыгаруу',
  finishTheTask: 'Аяктоо',
  route: 'Маршрут',
  routes: 'Маршруттар',
  routesList: 'Маршруттар тизмеси',
  routeStatus: 'Маршрутун статусу',
  routeStartDate: 'Башталган күнү',
  routeGenerator: 'Маршруттар генератору',
  routePlannedDateTime: 'Мерчемделген убакыт',
  routeRealDateTime: 'Чыныгы убакыт',
  routeCreated: 'Маршрут түзүлдү',
  routeFormTitle: 'Маршруттарды калыптандыруу',
  routeCreateTitle: 'Маршрутту түзүү',
  routeCalculated: 'Маршрут эсептелди',
  routeDelete: 'Маршрутту өчүрүү',
  addTasksToRoute: 'Маршрутка кошуу',
  courier: 'Курьер',
  couriers: 'Курьерлер',
  couriersList: 'Курьерлер тизмеси',
  courierEditSuccess: 'Курьер ийгиликтүү өзгөртүлдү',
  courierCreateSuccess: 'Курьер ийгиликтүү түзүлдү',
  courierEditTitle: 'Курьерди ондоо',
  courierCreateTitle: 'Курьерди кошуу',
  deliveryMethod: 'Жеткирүү ыкмасы',
  deliveryMethods: 'Жеткирүү ыкмалары',
  allDeliveryMethod: 'Бардык жеткирүү ыкмалары',
  withoutDeliveryMethod: 'Жеткирүү жок',
  deliveryMethodEditSuccess: 'Жеткирүү ыкмасы ийгиликтүү өзгөртүлдү',
  deliveryMethodCreateSuccess: 'Жеткирүү ыкмасы ийгиликтүү түзүлдү',
  deliveryMethodEditTitle: 'Жеткирүү ыкмасын оңдоо',
  deliveryMethodCreateTitle: 'Жеткирүү ыкмасын кошуу',
  deliveryMethodsParams: 'Жеткирүү ыкмасынын параметрлери',
  deliveryMethodTitle: 'Жеткирүү ыкмасынын аталышы',
  deliveryMethodCode: 'Жеткирүү ыкмасынын коду',
  clientGroup: 'Кардарлар тобу',
  clientGroups: 'Кардарлар топтору',
  clientGroupEditSuccess: 'Кардарлар тобу ийгиликтүү өзгөртүлдү',
  clientGroupCreateSuccess: 'Кардарлар тобу ийгиликтүү түзүлдү',
  clientGroupEditTitle: 'Кардарлар тобун оңдоо',
  clientGroupCreateTitle: 'Кардарлар тобун кошуу',
  warehouse: 'Кампа',
  warehouses: 'Кампалар',
  warehouseEdited: 'Кампа ийгиликтүү өзгөртүлдү',
  warehouseCreated: 'Кампа ийгиликтүү түзүлдү',
  warehouseEditTitle: 'Кампаны оңдоо',
  warehouseCreateTitle: 'Кампаны кошуу',
  warehouseHasNoCoords: 'Кампанын координаттары жок',
  warehouseSettingsEdited: 'Кампанын тууралоолору ийгиликтүү өзгөртүлдү',
  client: 'Кардар',
  shop: 'Дүкөн',
  shops: 'Дүкөндөр',
  shopEditSuccess: 'Дүкөн ийгиликтүү өзгөртүлдү',
  shopCreateSuccess: 'Дүкөн ийгиликтүү түзүлдү',
  shopEditTitle: 'Дүкөндү оңдоо',
  shopcCreateTitle: 'Дүкөндү кошуу',
  status: 'Статус',
  transport: 'Транспорт',
  transportNumber: 'Мамлекеттик номер',
  transportWeightWithUnit: 'Жүк көтөрүү жөндөмдүүлүгү, кг',
  transportEditSuccess: 'Транспорт ийгиликтүү өзгөртүлдү',
  transportCreateSuccess: 'Транспорт ийгиликтүү түзүлдү',
  transportEditTitle: 'Транспортту оңдоо',
  transportCreateTitle: 'Транспортту кошуу',
  user: 'Колдонуучу',
  users: 'Колдонуучулар',
  userEdited: 'Колдонуучу өзгөртүлдү',
  userCreated: 'Колдонуучу түзүлдү',
  userEditTitle: 'Колдонуучуну оңдоо',
  userCreateTitle: 'Колдонуучуну кошуу',
  privacy: 'Конфиденциялуулук саясаты',
  locations: 'Локациялар',
  locationsList: 'Локациялар тизмеси',
  locationCreateTitle: 'Локацияны кошуу',
  locationEditTitle: 'Локацияны оңдоо',
  locationWillBeSaved: 'Локация сакталат',
  locationCreated: 'Локация кошулду',
  locationEdited: 'Локация өзгөртүлдү',
  locationDeleted: 'Локация өчүрүлдү',
  rates: 'Тарифтер',
  ratesList: 'Тарифтер тизмеси',
  rateDateTimeStart: 'Тарифтин иштей башташы',
  rateDateTimeEnd: 'Тарифтин бүтүшү',
  rateEditTitle: 'Тарифти оңдоо',
  rateViewTitle: 'Тарифти көрүү',
  rateCreateTitle: 'Тарифти кошуу',
  rateCopyTitle: 'Тарифти көчүрүү',
  rateCreated: 'Тариф кошулду',
  rateEdited: 'Тариф өзгөртүлдү',
  rateDeleted: 'Тариф өчүрүлдү',
  createRate: 'Тарифти кошуу',
  finishTheRate: 'Тарифти аяктоо',
  serviceZone: 'Кызматтык аймак',
  serviceZones: 'Аймактар',
  serviceZonesList: 'Аймактардын тизмеси',
  withoutServiceZone: 'Кызматтык аймактарсыз',
  serviceZoneEditTitle: 'Кызматтык аймакты оңдоо',
  serviceZoneViewTitle: 'Кызматтык аймакты көрүү',
  serviceZoneCreateTitle: 'Кызматтык аймакты кошуу',
  createServiceZone: 'Кызматтык аймакты кошуу',
  serviceZoneWillBeSaved: 'Кызматтык аймак сакталат',
  serviceZoneCreated: 'Кызматтык аймак кошулду',
  serviceZoneEdited: 'Кызматтык аймак өзгөртүлдү',
  serviceZoneDeleted: 'Кызматтык аймак өчүрүлдү',
  coverages: 'Каптоолор',
  coveragesList: 'Каптоонун тизмеси',
  allCoverages: 'Бардык каптоо',
  coverageDeleted: 'Каптоо өчүрүлдү',
  coverageAdded: 'Каптоо кошулду',
  coverageEdited: 'Каптоо өзгөртүлдү',
  withoutCoverages: 'Каптоосуз',
  coverageEditTitle: 'Каптоону оңдоо',
  coverageViewTitle: 'Каптоону көрүү',
  coverageCreateTitle: 'Каптоону кошуу',
  schedules: 'Түзүм',
  scheduleEditTitle: 'Түзүмдү оңдоо',
  scheduleViewTitle: 'Түзүмдү көрүү',
  scheduleCreateTitle: 'Түзүмдү кошуу',
  scheduleDeleted: 'Түзүм өчүрүлдү',
  scheduleCopyTitle: 'Түзүмдү кайталоо',
  sessionsRoutes: 'Нөөмөттүн маршруту',
  timeslot: 'Убактылуу уяча',
  deliveryTime: 'тапшырманы аткаруунун мерчемделген же чыныгы убактысы',
  role: 'Роль',
  phone: 'Телефон',
  contractor: 'Контрагент',
  contactPerson: 'Байланыш адамы',
  contacts: 'Контакттар',
  comment: 'Ой-пикир',
  createdDateTime: 'Түзүлгөн күнү',
  creator: 'Түзүлдү', // Түзүлдү: Сидоров В.
  resolution: 'Резолюция',
  courierSessionOnDate: '{{content}} нөөмөт',
  courierSessionCreated: 'Жаңы нөөмөт туураланды',
  courierSessionEdited: 'Нөөмөт өзгөртүлдү',
  courierSessionDeleted: 'Нөөмөт ийгиликтүү өчүрүлдү',
  courierSessionSetup: 'Жаңы нөөмөттү тууралоо',
  courierSessionEditTitle: 'Нөөмөттү оңдоо',
  courierSessionCreateTitle: 'Нөөмөттү кошуу',

  // navigation
  goBackward: 'Артка кайтуу',
  goIndexPage: 'башкы бетке',
  goToCourierSession: 'Нөөмөткө кайтуу',
  goToServiceZoneCreation: 'Кызматтык аймакты түзүүгө өтүү',
  goToRateCreation: 'Тарифти түзүүгө өтүү',
  goToCoverageCreation: 'Каптоону түзүүгө өтүү',
  goToOrderList: 'Буйрутмалар тизмесине',
  goToRouteList: 'Мартшруттар тизмесине',
  goToCourierList: 'Курьерлер тизмесине',
  goToList: 'Тизмеге кайтуу',

  // actions
  authorize: 'Кирүү',
  save: 'Сактоо',
  calculate: 'Эсептөө',
  saveAll: 'Баарын сактоо',
  calculateAll: 'Баарын эсептөө',
  editCourier: 'Курьрди оңдоо',
  cancelTheRoute: 'Маршрутту жокко чыгаруу',
  cancelTheRoutes: 'Маршруттарды жокко чыгаруу',
  add: 'Кошуу',
  delete: 'Өчүрүү',
  edit: 'Оңдоо',
  createLocation: 'Локацияны кошуу',
  addTask: 'Тапшырманы кошуу',
  sendForLoading: 'Жүктөөгө жөнөтүү',
  geocode: 'Геокодирлөө',
  create: 'Түзүү',
  selectCourierForRoute: 'Курьрди жана машинаны тандоо',
  cleanup: 'Тазалоо',
  hideInactive: 'Активдүү эместерди жашыруу',
  editWarehouseSettings: 'Кампанын тууралоолорун өзгөртүү',
  addDeliveryMethod: 'Жеткирүү ыкмасын кошуу',
  removeLastDeliveryMethod: 'Акыркы жеткирүү ыкмасын өчүрүү',
  beginTheSession: 'Нөөмөттү баштоо',
  endTheSession: 'Нөөмөттү аяктоо',
  refresh: 'Жаңыртуу',
  select: 'Тандоо',
  apply: 'Колдонуу',
  showCoveragesByDeliveryMethod: 'Каптоонун жеткирүү ыкмалары боюнча көрсөтүү',
  applyToOtherDays: 'Жуманын башка күндөрүнө колдонуу',
  hidePast: 'Өткөндү жашыруу',

  // common
  yes: 'Ооба',
  no: 'Жок',
  or: 'же',
  cancel: 'Жокко чыгаруу',
  noData: 'Маалымат жок',
  nothingWasFound: 'Эч нерсе табылган жок',
  error: 'Ката',
  somethingIsWrong: 'Бир нерсе туура эмес кетти',
  areYouSure: 'Сиз ишеничтүүсүзбү?',
  sendToCourier: 'Курьерге жөнөтүү',
  lastSession: 'Акыркы нөөмөт',
  saved: 'сакталды',
  oneMoreCoverage: 'Дагы бир каптоо',
  addressTitle: 'Дареги',
  extraTask: 'Кошумча тапшырма',
  deliveryDate: 'Жеткирген күнү',
  timeSlotStart: 'Уячанын башы',
  timeSlotEnd: 'Уячанын аягы',
  onAddress: 'дареги боюнча',
  delivery: 'Жеткирүү',
  addressesLength: 'Даректери:\u00A0{{content}}',
  tasksLength: 'тапшырмалар:\u00A0{{content}}',
  onTheList: 'Тизме менен',
  onTheMap: 'Картада',
  loadingAtTime: '{{content}} жүктөө',
  comeToWarehouseAtTime: '{{content}} кампага кайтуу',
  sendToLoadTime: 'Жүктөөгө жөнөтүү убактысы',
  weightFullness: 'Салмагы боюнча жүктөө',
  volumeFullness: 'Көлөмү боюнча жүктөө',
  filters: 'Фильтрлер',
  selected: 'Тандалды',
  personalData: 'Өздүк маалыматтар',
  authentication: 'Аутентификация',
  parameters: 'Параметрлер',
  mainWarehouseSettings: 'Негизги тууралоолор',
  problem: 'Көйгөйлүү',
  mondayShort: 'ДШ',
  tuesdayShort: 'БШ',
  wednesdayShort: 'ШР',
  thursdayShort: 'ШШ',
  fridayShort: 'ЖМ',
  saturdayShort: 'ИШ',
  sundayShort: 'ЖК',
  time: 'Сааты',
  period: 'Жарактуулук маалы',
  number: 'Номер',
  atTime: '{{content}} га',
  fromTime: '{{content}} менен',
  totalCount: 'Бардыгы',
  sizes: 'Өлчөмдөрү',
  height: 'Бийиктиги',
  heightWithUnit: 'Бийиктиги, м.',
  heightTemplate: 'Б: {{content}} м.',
  width: 'Туурасы',
  widthWithUnit: 'Туурасы, м.',
  widthTemplate: 'Т: {{content}} м.',
  length: 'Узундугу',
  lengthWithUnit: 'Узундугу, м.',
  lengthTemplate: 'У: {{content}} м.',
  type: 'Түрү',
  automatic: 'автоматтык',
  changedMyMind: 'Мен кайра ойлондум',
  priceUnitRub: 'руб.',
  averageSpeedUnitKmPerHour: 'км/с',
  timeUnitDayShort: 'к.',
  timeUnitHourShort: 'с.',
  timeUnitMinuteShort: 'мүн.',
  weightUnit: 'кг',
  lengthUnit: 'м',
  forDate: '{{content}} га',
  byOrder: 'буйрутма менен',
  search: 'Издөө',
  searchByTitle: 'Аталышы боюнча издөө',
  searchByLocation: 'Локациялар боюнча издөө',
  searchByNumber: 'Номер боюнча издөө',
  searchByTitleOrNumber: 'Аталышы жана мамлекеттик номер боюнча издөө',
  searchByFullNameEmailPhone: 'Аты-жөнү, email же телефону боюнча издөө',
  searchByTitleAddressPhone: 'Аталышы, дареги же телефону боюнча издөө',

  // form fields
  date: 'Күн',
  from: 'Башталышы',
  to: 'Аягы',
  disabled: 'активдүү эмес',
  activity: 'Активдүүлүгү',
  title: 'Аталышы',
  id: 'Идентификатор',
  externalId: 'Сырткы системадагы идентификатор',
  isActivated: 'Активдештирилди',
  isActive: 'Активдүү',
  login: 'Логин',
  password: 'Сырсөз',
  passwordConfirmation: 'Сырсөздү тастыктоо',
  sessionPlannedDate: 'Нөөмөттүн башталган күнү',
  fullName: 'Аты-жөнү',
  coverageCreation: 'Каптоону түзүү',
  newCoverage: 'Жаңы каптоо',
  polygon: 'Полигон',
  autoDrawPolygon: 'Полигонду кайра тартуу',
  autoDraw: 'Кайра тартуу',
  selectAll: 'Барын тандоо',
  parentLocation: 'Ата-энелик локация',
  positionOnTheMap: 'Картада жайгашканы',
  destination: 'Багытталган орду',
  sender: 'Жөнөтүүчү',
  recipient: 'Алуучу',
  plannedDateTime: 'Күтүлүүчү жеткирүү убактысы',
  taskCancelationReason: 'Тапшырманы жокко чыгаруу себеби',
  groupTasksByCoverage: 'Каптоолор боюнча топтоо',
  dayStart: 'Күндүн башталышы',
  dayEnd: 'Күндүн аягы',
  interval: 'Интервал',
  setUpEqualIntervals: 'Бирдиктүү интервалдарды тууралоо',
  orderLimit: 'Буйрутмалардын чектелиши',
  price: 'Баасы',
  averageSpeed: 'Орточо ылдамдыгы',
  addNewRow: 'Сызык кошуу',
  shopShortName: 'Кыскача аталышы',
  shopLegalName: 'Юридикалык аталышы',
  shopCurrency: 'Валюта',
  volumeWithUnit: 'Көлөмү, куб. м',
  loadingTime: 'Жүктөө убактысы',
  surname: 'Фамилия',
  name: 'Аты',
  patronymic: 'Атасынын аты',
  userStatus: 'Колдонуучунун статусу',
  latitude: 'Кеңдиги',
  longitude: 'Узундугу',
  timezone: 'Таймаймак',
  warehouseGroupId: 'Кампалар тобунун идентификатору',
  maxRouteTime: 'Маршруттун максималдык убактысы',
  maxCourierDowntime: 'Курьер турган максималдык убакыт',
  maxDelayTime: 'Кечигүүнүн масималдык убактысы',
  maxLeadTime: 'Алдын алуунун масималдык убактысы',
  unloadingTime: 'Түшүрүү убактысы',
  guardLimit: 'Коргоочу интервал',
  fullDayRoutes: 'Маршруттар менен күндү толтуруу',
  weight: 'Салмагы',
  volume: 'Көлөмү',
  sum: 'Сумма',
  enterTitle: 'Аталышын жазыңыз',
  enterId: 'Идентификаторду жазыңыз',
  enterLogin: 'Логинди жазыңыз',
  enterPassword: 'Сырсөздү жазыңыз',
  enterPasswordConfirmation: 'Тастыкталган сырсөздү жазыңыз',
  enterTransport: 'Транспортту тандаңыз',
  enterDate: 'Күндү тандаңыз',
  enterUserName: 'Колдонуучунун атын жазыңыз',
  enterPolygon: 'Каптоо ээлеген облусту картадан белгилеңиз',
  enterLocationName: 'Локацияны жазыңыз',
  enterLocation: 'Локацияны тандаңыз',
  enterWarehouse: 'Кампаны тандаңыз',
  enterWarehouses: 'Кампаларды тандаңыз',
  enterLocationCenter: 'Масштабды тандап жана маркерди локациянын борборуна жайгаштырыңыз',
  enterSearchQueryAddress: 'Издөө үчүн даректи тандаңыз',
  enterTaskType: 'Тапшырманын түрүн тандаңыз',
  enterDestination: 'Белгиленген жерди тандаңыз',
  enterTime: 'Убактысын тандаңыз',
  enterCourier: 'Курьерди тандаңыз',
  enterHeight: 'Бийиктигин жазыңыз',
  enterWidth: 'Кеңдигин жазыңыз',
  enterLength: 'Узундугун жазыңыз',
  enterTaskCancelationReason: 'Себебин тандаңыз',
  enterServiceZone: 'Кызматтык аймакты тандаңыз',
  enterDeliveryMethod: 'Жеткирүү ыкмасын тандаңыз',
  enterCoverages: 'Каптоону тандаңыз',
  enterDayStart: 'Күндүн башталышын көрсөтүңүз',
  enterDayEnd: 'Күндүн аягын көрсөтүңүз',
  enterInterval: 'Интервалды көрсөтүңүз',
  enterOrderLimit: 'Чектөөнү көрсөтүңүз',
  enterPrice: 'Баасын көрсөтүңүз',
  enterAverageSpeed: 'Ылдамдыгын жазыңыз',
  enterShopShortName: 'Кыскача аталышын жазыңыз',
  enterShopLegalName: 'Юридикалык аталышын жазыңыз',
  enterTransportNumber: 'Мамлекеттик номерин жазыңыз',
  enterTransportWeight: 'Жүк көтөрүү жөндөмдүүлүгүн жазыңыз',
  enterLoadingTime: 'Убактысын жазыңыз',
  enterSurname: 'Фамилиясын жазыңыз',
  enterName: 'Атын жазыңыз',
  enterPatronymic: 'Атасынын атын жазыңыз',
  enterEmail: 'Email жазыңыз',
  enterPhone: 'Телефонду жазыңыз',
  enterRole: 'Роль тандаңыз',
  enterAddress: 'Дарегин жазыңыз',
  enterLatitude: 'Кеңдигин жазыңыз',
  enterLongitude: 'Узундугун жазыңыз',
  enterPoint: 'Картадагы пункту көрсөтүңүз',
  enterTimezone: 'Таймаймакты тандаңыз',
  enterWarehouseGroupId: 'Кампалар тобунун идентификаторун жазыңыз',
  enterShops: 'Дүкөндү тандаңыз',
  enterMaxRouteTime: 'Маршруттун убактысын жазыңыз',
  enterMaxDelayTime: 'Кечигүүнүн убактысын жазыңыз',
  enterMaxCourierDowntime: 'Курьердин турган убактысын жазыңыз',
  enterMaxLeadTime: 'Алдын алуу убактысн жазыңыз',
  enterUnloadingTime: 'Түшүрүүнүн убактысын жазыңыз',
  enterGuardLimit: 'Корголуучу интервалды жазыңыз',
  enterWeight: 'Салмагын жазыңыз',
  enterVolume: 'Көлөмүн жазыңыз',

  // other
  possiblyLate: 'Кечигүүсү мүмкүн',
  latenessTitle: 'Кечигүү',
  lateness_one: 'кечигүү',
  lateness_few: 'кечигүүлөр',
  lateness_many: 'кечигүүлөрдү',
  lateness_other: 'кечигүүлөрдү',
  possiblyOutrunning: 'Озуу мүмкүн',
  outrunningTitle: 'Озуу',
  outrunning_one: 'озуу',
  outrunning_few: 'озуулар',
  outrunning_many: 'озууларды',
  outrunning_other: 'озууларды',
  task_one: 'тапшырма',
  task_few: 'тапшырмалар',
  task_many: 'тапшырмаларды',
  task_other: 'тапшырмаларды',
  coverage_one: 'каптоо',
  coverage_few: 'каптоолор',
  coverage_many: 'каптоолорду',
  coverage_other: 'каптоолорду',
  address_one: 'дареги',
  address_few: 'даректер',
  address_many: 'даректер',
  address_other: 'даректер',

  // validators
  passwordRequiredValidator: 'Сураныч, сырсөздү көрсөтүңүз',
  loginRequiredValidator: 'Сураныч, логинди көрсөтүңүз',
  transportRequiredValidator: 'Транспорт каражатын тандаңыз',
  requiredValidator: 'Талаа сөзсүз толтурулушу керек',
  moreThanNullNumberValidator: 'Мааниси нөлдөн көп болушу керек',
  positiveNumberValidator: 'Мааниси оң болушу керек',
  integerNumberValidator: 'Мааниси бүтүн болушу керек',
  compareWithPasswordValidator: 'Сырсөздөр дал келиши керек',
  moreThanNullZoomValidator: 'Масштаб нөлдөн көп болушу керек',
  minIntervalValidator: 'Уруксат берилген минималдык интервал – 5 мүнөт',
  maxIntervalValidator: 'Уруксат берилген максималдык интервал – 1 сутка',
  orderLimitValidator: 'Буйрутмалардын чектөөсү 0 дон аз болбошу керек',
  priceValidator: 'Баасы 0 дон аз болбошу керек',
  surnameValidator: 'Сураныч, фамилияны жазыңыз',
  nameValidator: 'Сураныч, атты жазыңыз',
  roleValidator: 'Сураныч, ролду тандаңыз',
  scheduleRequiredValidator: 'Түзүмдү кошуу керек',

  // confirm
  defaultConfirm: ' {{content}} каалап жатканыңызга ишеничтүүсүзбү',
  deliveryMethodDeleteConfirm: 'Жеткирүү ыкмасын өчүрүү керекпи?',
  clientGroupDeleteConfirm: 'Кардарлар тобун өчүрүү керекпи?',
  warehouseDeleteConfirm: 'Кампаны өчүрүү керекпи?',
  shopDeleteConfirm: 'Дүкөндү өчүрүү керекпи?',
  transportDeleteConfirm: 'Транспортту өчүрүү керекпи?',
  finishTheRateConfirm: 'Тарифти аяктайлыбы? Тариф ишин 1 мүнөттөн кийин токтотот.',
  leaveThePageConfirm: 'Барактан чыгууну каалап жаткандыгынызга ишеничтүүсүзбү?',
  timeSlotDeleteConfirm: 'Убактылуу уячаны өчүрүү керекпи?',
  courierDeleteConfirm: 'Курьерди өчүрүү керекпи?',
  userDeleteConfirm: 'Колдонуучуну өчүрүү керекпи?',
  locationDeleteConfirm: 'Локацияны өчүрүү керекпи?',
  rateDeleteConfirm: 'Тарифти өчүрүү керекпи?',
  serviceZoneDeleteConfirm: 'Кызматтык аймакты өчүрүү керекпи?',
  coverageDeleteConfirm: 'Каптоону өчүрүү керекпи?',
  coverageDelete: 'Каптоону өчүрүү керекпи',
  coverageDeleteFromListConfirm: 'Каптоону тизмеден өчүрүү керекпи?',
  endCourierSessionConfirm:
    'Сиз чынында эле нөөмөттү аяктоону каалайсызбы? Бүтпөй калган маршруттар жокко чыгарылат.',

  // notifications & phrases
  addressIsNotDefined: 'Дареги аныкталган эмес',
  cannotCallTheClient: 'Курьер кардарга чала алган жок',
  courierIsNotSelected: 'Курьер жана машина тандалган жок',
  sessionWillBeCreateAutomatically: 'Нөөмөт автоматтык түрдө түзүлөт',
  transportIsNotCompareToWarehouse: 'Кампа жеткиликтүү автомобиль менен дал келбейт',
  courierIsNotCompareToWarehouse: 'Кампа жеткиликтүү курьерге дал келбейт',
  transportIsNotCompareToDeliveryMethod: 'Жеткирүү ыкмасы жеткиликтүү автомобиль менен дал келбейт',
  thereIsActiveRoutesInSession: 'Нөөмөттө аяктай эле маршруттар бар',
  locationHasNoRates: 'Локациянын бир дагы тарифи жок',
  letsCreateRoute: 'Маршрутту түзүү үчүн бул жакка тапшырманы алып келиңиз.',
  thereAreNoZones: 'Бул жерде бир дагы аймак жок',
  thereAreSomeProblems: 'Маселелер табылды',
  noSectionFound: 'Бөлүм жок',
  accessDenied: 'Кирүүгө тыюу салынган',
  noTasks: 'Тапшырмалар жок',
  notDefined: 'Аныкталган жок',
  thereAreTasksWithNotAvailableDeliveryMethodsForTransport:
    'Автомобиль бара албайт: жеткирүү ыкмасы жеткиликтүү эмес тапшырмалар бар',
  thereAreTasksWithNotAvailableWarehouseForTransport:
    'Автомобиль бара албайт: жеткиликтүү эмес кампалуу тапшырмалар бар',
  thereAreTasksWithNotAvailableWarehouseForCourier:
    'Курьер бара албайт: жеткиликтүү эмес кампалуу тапшырмалар бар',
  letsCreateCourierSession:
    'Курьерге ушул күнгө нөөмөттү мерчемдөө үчүн "Нөөмөттү кошууну" басыңыз',
  warehouseCreatedWithNoCoords:
    'Кампа геокоддоо катасы менен түзүлдү. Активдештирүү үчүн кампанын дарегин тактаңыз',
  maxDelayTimeMeaning:
    'Эгер 0 мааниси коюлса, кечирүүгө тыюу салынат. Эгер талаа толтурулбаса, анда чектөө коюлбайт',
  maxLeadTimeMeaning:
    'Эгер 0 мааниси коюлса, озууга тыюу салынат. Эгер талаа толтурулбаса, анда чектөө коюлбайт',
  fullDayRoutesMeaning: 'Буйрутмаларды бир күнгө бөлүштүрүү',
  searchByFullName: 'Аты-жөнү боюнча издөө',
  defaultSort: 'Суратпай сорттоо',
  ascendingAmountSort: 'сумманын жогорулоосу',
  descendingAmountSort: 'Сумманын азайышы',
  ascendingWeightSort: 'Салмактын жогорулоосу',
  descendingWeightSort: 'салмактын азайышы',
  ascendingVolumeSort: 'Көлөмдүн жогорулоосу',
  descendingVolumeSort: 'Көлөмдүн азайышы',
  couriersSuccessfullySend: 'Курьерлер жүктөп түшүрүүгө жөнөтүлдү',
  courierHasProcessingRoute: 'Курьердин аткаруудагы маршруту бар',
  courierHasProcessingSession: 'Курьердин аткаруудагы нөөмөтү бар',
  readyToSendCouriers: 'курьерлер жөнөтүүгө даяр',
  noCouriersToSend: 'Жөнөтүүгө бир дагы курьер жок',
  addExtraTask: 'Кошумча тапшырма жаратуу',
  taskCreationReason: 'Себебин жазыңыз',
  extraTaskPreRequest: 'Курьер кардар менен жолуктубу?',
  extraTaskReasons: 'Кошумча тапшырма жаратылуусу керек эгер:',
  extraTaskReason1: 'Кайтарууну каттоо',
  extraTaskReason2: 'Товарды жеткирүү',
  extraTaskReason3: 'Товарды алмаштыруу',
  extraTaskWarning: 'Мындай тапшырма Каттоо системасында көрсөтүлбөйт',
  extraTaskAction: 'Башка себеп болсо Техколдоого кайрылыңыз',
};
