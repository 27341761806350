import React from 'react';
import 'leaflet.markercluster';

import {
  extendContext,
  createElementObject,
  createPathComponent,
  // eslint-disable-next-line import/named
  LeafletContextInterface,
} from '@react-leaflet/core';
import L, { LeafletMouseEventHandlerFn } from 'leaflet';

import markerIconRetinaUrl from 'icons/marker-icon-2x.png';
import markerIconUrl from 'icons/marker-icon.png';
import markerShadowUrl from 'icons/marker-shadow.png';

L.Icon.Default.mergeOptions({
  iconRetinaUrl: markerIconRetinaUrl,
  iconUrl: markerIconUrl,
  shadowUrl: markerShadowUrl,
});

// eslint-disable-next-line
type ClusterType = { [key in string]: any };

type ClusterEvents = {
  onClick?: LeafletMouseEventHandlerFn;
  onDblClick?: LeafletMouseEventHandlerFn;
  onMouseDown?: LeafletMouseEventHandlerFn;
  onMouseUp?: LeafletMouseEventHandlerFn;
  onMouseOver?: LeafletMouseEventHandlerFn;
  onMouseOut?: LeafletMouseEventHandlerFn;
  onContextMenu?: LeafletMouseEventHandlerFn;
};

type MarkerClusterControl = ClusterEvents &
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  L.MarkerClusterGroupOptions & {
    children: React.ReactNode;
  };

function getPropsAndEvents(props: MarkerClusterControl) {
  let clusterProps: ClusterType = {};
  let clusterEvents: ClusterType = {};
  // eslint-disable-next-line
  const { children, ...rest } = props;

  Object.entries(rest).forEach(([propName, prop]) => {
    if (propName.startsWith('on')) {
      clusterEvents = { ...clusterEvents, [propName]: prop };
    } else {
      clusterProps = { ...clusterProps, [propName]: prop };
    }
  });

  return { clusterProps, clusterEvents };
}

function createMarkerClusterGroup(props: MarkerClusterControl, context: LeafletContextInterface) {
  const { clusterProps, clusterEvents } = getPropsAndEvents(props);
  const markerClusterGroup = new L.MarkerClusterGroup(clusterProps);

  Object.entries(clusterEvents).forEach(([eventAsProp, callback]) => {
    const clusterEvent = `cluster${eventAsProp.substring(2).toLowerCase()}`;
    markerClusterGroup.on(clusterEvent, callback);
  });

  return createElementObject(
    markerClusterGroup,
    extendContext(context, { layerContainer: markerClusterGroup }),
  );
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const updateMarkerCluster = (
  // eslint-disable-next-line
  instance: L.MarkerClusterGroup,
  // eslint-disable-next-line
  props: MarkerClusterControl,
  // eslint-disable-next-line
  prevProps: MarkerClusterControl,
) => {
  // TODO: обновить instance, если props изменились
};

const MarkerClusterGroup = createPathComponent<L.MarkerClusterGroup, MarkerClusterControl>(
  createMarkerClusterGroup,
  updateMarkerCluster,
);

export default MarkerClusterGroup;
