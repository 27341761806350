export const en_translations: Record<string, string> = {
  planning: 'Planning',
  tariffing: 'Tarification',
  directory: 'Guide',
  logout: 'Exit',
  order: 'Order',
  orders: 'Orders',
  ordersList: 'Order list',
  orderStatus: 'Order status',
  orderDeliveryDateTime: 'Estimated time',
  orderSizes: 'Cargo parameters',
  orderParameters: 'Order parameters',
  orderType: 'Type',
  orderDetails: 'Order details',
  productView: 'Product type',
  taskTitle: 'Task',
  tasks: 'Tasks',
  tasksList: 'Task list',
  taskType: 'Task type',
  taskStatus: 'Task status',
  taskDeliveryDateTime: 'Estimated time',
  taskPlannedDeliveryDateTime: 'Planned time',
  taskRealDeliveryDateTime: 'Actual time',
  taskSizes: 'Cargo parameters',
  removeTaskFromRoute: 'Remove from the route',
  cancelTheTask: 'Cancel the task',
  taskCancelation: 'Task cancel',
  finishTheTask: 'Finish',
  route: 'Route',
  routes: 'Routes',
  routesList: 'Route list',
  routeStatus: 'Route status',
  routeStartDate: 'Start date',
  routeGenerator: 'Routes generator',
  routePlannedDateTime: 'Planned time',
  routeRealDateTime: 'Actual time',
  routeCreated: 'The route is created',
  routeFormTitle: 'Route forming',
  routeCreateTitle: 'Route creation',
  routeCalculated: 'The route is computed',
  routeDelete: 'Delete the route',
  addTasksToRoute: 'Add to the route',
  courier: 'Courier',
  couriers: 'Couriers',
  couriersList: 'Courier list',
  courierEditSuccess: 'Courier successfully changed',
  courierCreateSuccess: 'Courier successfully created',
  courierEditTitle: 'Edit couriers',
  courierCreateTitle: 'Add couriers',
  deliveryMethod: 'Delivery method',
  deliveryMethods: 'Delivery methods',
  allDeliveryMethod: 'All delivery methods',
  withoutDeliveryMethod: 'No delivery',
  deliveryMethodEditSuccess: 'Delivery method successfully changed',
  deliveryMethodCreateSuccess: 'Delivery method successfully created',
  deliveryMethodEditTitle: 'Edit delivery methods',
  deliveryMethodCreateTitle: 'Add delivery methods',
  deliveryMethodsParams: 'Delivery methods parameters',
  deliveryMethodTitle: 'Delivery method name',
  deliveryMethodCode: 'Delivery method code',
  clientGroup: 'Customer group',
  clientGroups: 'Customer groups',
  clientGroupEditSuccess: 'Customer group successfully changed',
  clientGroupCreateSuccess: 'Customer group successfully created',
  clientGroupEditTitle: 'Edit customer groups',
  clientGroupCreateTitle: 'Add customer groups',
  warehouse: 'Stock',
  warehouses: 'Stocks',
  warehouseEdited: 'Stock is successfully changed',
  warehouseCreated: 'Stock is successfully created',
  warehouseEditTitle: 'Edit stocks',
  warehouseCreateTitle: 'Add stocks',
  warehouseHasNoCoords: 'No stock coordinates',
  warehouseSettingsEdited: 'Stock settings successfully changed',
  client: 'Customer',
  shop: 'Store',
  shops: 'Stores',
  shopEditSuccess: 'Store successfully changed',
  shopCreateSuccess: 'Store successfully created',
  shopEditTitle: 'Edit stores',
  shopcCreateTitle: 'Add stores',
  status: 'Status',
  transport: 'Transport',
  transportNumber: 'License plate',
  transportWeightWithUnit: 'Carrying capacity, kg',
  transportEditSuccess: 'Store successfully changed',
  transportCreateSuccess: 'Store successfully created',
  transportEditTitle: 'Edit transport',
  transportCreateTitle: 'Add transport',
  user: 'User',
  users: 'Users',
  userEdited: 'User changed',
  userCreated: 'User created',
  userEditTitle: 'Edit users',
  userCreateTitle: 'Add users',
  privacy: 'Confidentiality Policy',
  locations: 'Locations',
  locationsList: 'Location list',
  locationCreateTitle: 'Add locations',
  locationEditTitle: 'Edit locations',
  locationWillBeSaved: 'The location will be saved',
  locationCreated: 'Location added',
  locationEdited: 'Location changed',
  locationDeleted: 'Location deleted',
  rates: 'Tariffs',
  ratesList: 'Tariff list',
  rateDateTimeStart: 'Tariff validity start',
  rateDateTimeEnd: 'Tariff validity end',
  rateEditTitle: 'Edit tariffs',
  rateViewTitle: 'View tariffs',
  rateCreateTitle: 'Add tariffs',
  rateCopyTitle: 'Copy tariffs',
  rateCreated: 'Tariff added',
  rateEdited: 'Tariff changed',
  rateDeleted: 'Tariff deleted',
  createRate: 'Add a tariff',
  finishTheRate: 'End a tariff',
  serviceZone: 'Service zone',
  serviceZones: 'Zones',
  serviceZonesList: 'Zone list',
  withoutServiceZone: 'No service zone',
  serviceZoneEditTitle: 'Edit service zones',
  serviceZoneViewTitle: 'View service zones',
  serviceZoneCreateTitle: 'Add service zones',
  createServiceZone: 'Add a service zone',
  serviceZoneWillBeSaved: 'Service zone will be saved',
  serviceZoneCreated: 'Service zone added',
  serviceZoneEdited: 'Service zone changed',
  serviceZoneDeleted: 'Service zone deleted',
  coverages: 'Coverages',
  coveragesList: 'Covering list',
  allCoverages: 'All coverings',
  coverageDeleted: 'Covering deleted',
  coverageAdded: 'Covering added',
  coverageEdited: 'Covering changed',
  withoutCoverages: 'No covering',
  coverageEditTitle: 'Edit covering',
  coverageViewTitle: 'View covering',
  coverageCreateTitle: 'Add covering',
  schedules: 'Schedules',
  scheduleEditTitle: 'Edit schedules',
  scheduleViewTitle: 'View schedules',
  scheduleCreateTitle: 'Add schedules',
  scheduleDeleted: 'Schedule deleted',
  scheduleCopyTitle: 'Duplicate a schedule',
  sessionsRoutes: 'Shift routes',
  timeslot: 'Time slot',
  deliveryTime: 'planned or actual time of the task completion',
  role: 'Role',
  phone: 'Phone number',
  contractor: 'Business partner',
  contactPerson: 'Contact person',
  contacts: 'Contacts',
  comment: 'Comments',
  createdDateTime: 'Date of creation',
  creator: 'Created',
  resolution: 'Resolution',
  courierSessionOnDate: 'Shift from {{content}}',
  courierSessionCreated: 'New shift is set',
  courierSessionEdited: 'Shift changed',
  courierSessionDeleted: 'Shift successfully deleted',
  courierSessionSetup: 'Set a new shift',
  courierSessionEditTitle: 'Edit shifts',
  courierSessionCreateTitle: 'Add shifts',

  // navigation
  goBackward: 'Go back',
  goIndexPage: 'to the main page',
  goToCourierSession: 'Go back to the shift',
  goToServiceZoneCreation: 'Go to service zone creation',
  goToRateCreation: 'Go to tariff creation',
  goToCoverageCreation: 'Go to covering creation',
  goToOrderList: 'To the order list',
  goToRouteList: 'To the route list',
  goToCourierList: 'To the courier list',
  goToList: 'Go back to the list',

  // actions
  authorize: 'Log in',
  save: 'Save',
  calculate: 'Compute',
  saveAll: 'Save all',
  calculateAll: 'Compute all',
  editCourier: 'Edit a courier',
  cancelTheRoute: 'Cancel a route',
  cancelTheRoutes: 'Cancel routes',
  add: 'Add',
  delete: 'Delete',
  edit: 'Edit',
  createLocation: 'Add a location',
  addTask: 'Add a task',
  sendForLoading: 'Send for loading',
  geocode: 'Geocode',
  create: 'Create',
  selectCourierForRoute: 'Select a courier and a car',
  cleanup: 'Clear',
  hideInactive: 'Hide inactive',
  editWarehouseSettings: 'Open stock settings',
  addDeliveryMethod: 'Add a delivery method',
  removeLastDeliveryMethod: 'Delete the last delivery method',
  beginTheSession: 'Start the shift',
  endTheSession: 'Finish the shift',
  refresh: 'Update',
  select: 'Select',
  apply: 'Apply',
  showCoveragesByDeliveryMethod: 'Show coverings by delivery methods',
  applyToOtherDays: 'Apply to other days of week',
  hidePast: 'Hide passed ones',

  // common
  yes: 'Yes',
  no: 'No',
  or: 'or',
  cancel: 'Cancel',
  noData: 'No data available',
  nothingWasFound: 'Nothing found',
  error: 'Error',
  somethingIsWrong: 'Something went wrong',
  areYouSure: 'Are you sure?',
  sendToCourier: 'Send to courier',
  lastSession: 'The last shift',
  saved: 'saved',
  oneMoreCoverage: 'Another covering',
  addressTitle: 'Address',
  extraTask: 'Additional task',
  deliveryDate: 'Delivery date',
  timeSlotStart: 'Slot start',
  timeSlotEnd: 'Slot end',
  onAddress: 'to the address',
  delivery: 'Delivery',
  addressesLength: 'Addresses:\u00A0{{content}}',
  tasksLength: 'tasks:\u00A0{{content}}',
  onTheList: 'In the list',
  onTheMap: 'On the map',
  loadingAtTime: 'H {{content}} loading',
  comeToWarehouseAtTime: 'H {{content}} return to the stock',
  sendToLoadTime: 'Time of sending for loading',
  weightFullness: 'Load by weight',
  volumeFullness: 'Load by volume',
  filters: 'Filters',
  selected: 'Selected',
  personalData: 'Personal data',
  authentication: 'Authentification',
  parameters: 'Parameters',
  mainWarehouseSettings: 'Main settings',
  problem: 'Problematic',
  mondayShort: 'MON',
  tuesdayShort: 'TUE',
  wednesdayShort: 'WED',
  thursdayShort: 'THU',
  fridayShort: 'FRI',
  saturdayShort: 'SAT',
  sundayShort: 'SUN',
  time: 'Time',
  period: 'Validity period',
  number: 'Number',
  atTime: 'to {{content}}',
  fromTime: 'from {{content}}',
  totalCount: 'Total',
  sizes: 'Dimensions',
  height: 'Height',
  heightWithUnit: 'Height, m.',
  heightTemplate: 'H: {{content}} m.',
  width: 'Width',
  widthWithUnit: 'Width, m.',
  widthTemplate: 'W: {{content}} m.',
  length: 'Length',
  lengthWithUnit: 'Length, m.',
  lengthTemplate: 'L: {{content}} m.',
  type: 'Type',
  automatic: 'automatically',
  changedMyMind: 'I’ve changed my mind',
  priceUnitRub: 'RUB',
  averageSpeedUnitKmPerHour: 'km/h',
  timeUnitDayShort: 'd.',
  timeUnitHourShort: 'h.',
  timeUnitMinuteShort: 'min.',
  weightUnit: 'kg',
  lengthUnit: 'm',
  forDate: 'on {{content}}',
  byOrder: 'by the order',
  search: 'Search',
  searchByTitle: 'Search by name',
  searchByLocation: 'Search by locations',
  searchByNumber: 'Search by number',
  searchByTitleOrNumber: 'Search by name or license plate',
  searchByFullNameEmailPhone: 'Search by full name, email, or telephone',
  searchByTitleAddressPhone: 'Search by name, address, or telephone',

  // form fields
  from: 'Start',
  to: 'End',
  date: 'Date',
  disabled: 'inactive',
  activity: 'Activity',
  title: 'Name',
  id: 'Identifier',
  externalId: 'Identifier in the external system',
  isActivated: 'Activated',
  isActive: 'Active',
  login: 'Username',
  password: 'Password',
  passwordConfirmation: 'Password confirmation',
  sessionPlannedDate: 'Shift start date',
  fullName: 'Full name',
  coverageCreation: 'Covering creation',
  newCoverage: 'New covering',
  polygon: 'Polygon',
  autoDrawPolygon: 'Draw a polygon',
  autoDraw: 'Draw',
  selectAll: 'Select all',
  parentLocation: 'Parent location',
  positionOnTheMap: 'Position on the map',
  destination: 'Point of destination',
  sender: 'Sender',
  recipient: 'Recipient',
  plannedDateTime: 'Estimated time of delivery',
  taskCancelationReason: 'Task cancel reason',
  groupTasksByCoverage: 'Group by covering',
  dayStart: 'Start of the day',
  dayEnd: 'End of the day',
  interval: 'Interval',
  setUpEqualIntervals: 'Set equal intervals',
  orderLimit: 'Orders limit',
  price: 'Cost',
  averageSpeed: 'Average speed',
  addNewRow: 'Add line',
  shopShortName: 'Short name',
  shopLegalName: 'Legal name',
  shopCurrency: 'Currency',
  volumeWithUnit: 'Volume, cubic meters',
  loadingTime: 'Loading time',
  surname: 'Last name',
  name: 'First name',
  patronymic: 'Patronymic',
  userStatus: 'User status',
  latitude: 'Latitude',
  longitude: 'Longitude',
  timezone: 'Time zone',
  warehouseGroupId: 'Stock group identifier',
  maxRouteTime: 'Maximum route time',
  maxCourierDowntime: 'Maximum time of courier downtime',
  maxDelayTime: 'Maximum late time',
  maxLeadTime: 'Maximum lead time',
  unloadingTime: 'Unloading time',
  guardLimit: 'Guard time',
  fullDayRoutes: 'Filling with routes for the whole day',
  weight: 'Weight',
  volume: 'Volume',
  sum: 'Amount',
  enterTitle: 'Enter name',
  enterId: 'Enter identifier',
  enterLogin: 'Enter login',
  enterPassword: 'Enter password',
  enterPasswordConfirmation: 'Enter password confirmation',
  enterTransport: 'Select transport',
  enterDate: 'Select a date',
  enterUserName: 'Enter user name',
  enterPolygon: 'Mark the coverage area on the map',
  enterLocationName: 'Enter location',
  enterLocation: 'Select a location',
  enterWarehouse: 'Select a stock',
  enterWarehouses: 'Select stocks',
  enterLocationCenter: 'Pick a scale and place a marker in the center of the location',
  enterSearchQueryAddress: 'Enter address for search',
  enterTaskType: 'Select the task type',
  enterDestination: 'Select the destination point',
  enterTime: 'Select the time',
  enterCourier: 'Select the courier',
  enterHeight: 'Enter height',
  enterWidth: 'Enter width',
  enterLength: 'Enter length',
  enterTaskCancelationReason: 'Select the reason',
  enterServiceZone: 'Select the service zone',
  enterDeliveryMethod: 'Select the delivery method',
  enterCoverages: 'Select the covering',
  enterDayStart: 'Specify the start of the day',
  enterDayEnd: 'Specify the end of the day',
  enterInterval: 'Specify the interval',
  enterOrderLimit: 'Specify the limit',
  enterPrice: 'Specify the cost',
  enterAverageSpeed: 'Enter speed',
  enterShopShortName: 'Enter short name',
  enterShopLegalName: 'Enter legal name',
  enterTransportNumber: 'Enter license plate number',
  enterTransportWeight: 'Enter carrying capacity',
  enterLoadingTime: 'Enter time',
  enterSurname: 'Enter last name',
  enterName: 'Enter first name',
  enterPatronymic: 'Enter patronymic',
  enterEmail: 'Enter email',
  enterPhone: 'Enter telephone',
  enterRole: 'Select a role',
  enterAddress: 'Enter address',
  enterLatitude: 'Enter latitude',
  enterLongitude: 'Enter longitude',
  enterPoint: 'Specify a point on the map',
  enterTimezone: 'Select a time zone',
  enterWarehouseGroupId: 'Enter a stock group identifier',
  enterShops: 'Select stores',
  enterMaxRouteTime: 'Enter route time',
  enterMaxDelayTime: 'Enter late time',
  enterMaxCourierDowntime: 'Enter time of courier downtime',
  enterMaxLeadTime: 'Enter lead time',
  enterUnloadingTime: 'Enter unloading time',
  enterGuardLimit: 'Enter guard time',
  enterWeight: 'Enter weight',
  enterVolume: 'Enter volume',

  // other
  possiblyLate: 'Possible late arrival',
  latenessTitle: 'Late arrival',
  lateness_one: 'late arrival',
  lateness_few: 'late arrivals',
  lateness_many: 'late arrivals',
  lateness_other: 'late arrivals',
  possiblyOutrunning: 'Possible early arrival',
  outrunningTitle: 'Early arrival',
  outrunning_one: 'early arrival',
  outrunning_few: 'early arrivals',
  outrunning_many: 'early arrivals',
  outrunning_other: 'early arrivals',
  task_one: 'task',
  task_few: 'tasks',
  task_many: 'tasks',
  task_other: 'tasks',
  coverage_one: 'coverage',
  coverage_few: 'coverages',
  coverage_many: 'coverages',
  coverage_other: 'coverages',
  address_one: 'address',
  address_few: 'addresses',
  address_many: 'addresses',
  address_other: 'addresses',
  bag_one: 'bag',
  bag_few: 'bags',
  bag_many: 'bags',
  bag_other: 'bags',

  // validators
  passwordRequiredValidator: 'Please, specify password',
  loginRequiredValidator: 'Please, specify login',
  transportRequiredValidator: 'Select a transport',
  requiredValidator: 'This field is mandatory for filling',
  moreThanNullNumberValidator: 'The value must be more than zero',
  positiveNumberValidator: 'The value must be positive',
  integerNumberValidator: 'The value must be integer',
  compareWithPasswordValidator: 'The passwords must match',
  moreThanNullZoomValidator: 'The scale must be more than zero',
  minIntervalValidator: 'Minimum permissible interval is 5 minutes',
  maxIntervalValidator: 'Maximum permissible interval is 1 day',
  orderLimitValidator: 'Orders limit must not be less than 0',
  priceValidator: 'The cost must not be less than 0',
  surnameValidator: 'Please, enter your last name',
  nameValidator: 'Please, enter your first name',
  roleValidator: 'Please, select a role',
  scheduleRequiredValidator: 'You need to add schedule',

  // confirm
  defaultConfirm: 'Are you sure you want to {{content}}',
  deliveryMethodDeleteConfirm: 'Delete the delivery method?',
  clientGroupDeleteConfirm: 'Delete the customer group?',
  warehouseDeleteConfirm: 'Delete the stock?',
  shopDeleteConfirm: 'Delete the store?',
  transportDeleteConfirm: 'Delete the transport?',
  finishTheRateConfirm: 'End a tariff? The tariff becomes invalid in 1 minute.',
  leaveThePageConfirm: 'Are you sure you want to leave the page?',
  timeSlotDeleteConfirm: 'Delete the time slot?',
  courierDeleteConfirm: 'Delete the courier?',
  userDeleteConfirm: 'Delete the user?',
  locationDeleteConfirm: 'Delete the location?',
  rateDeleteConfirm: 'Delete the tariff?',
  serviceZoneDeleteConfirm: 'Delete the service zone?',
  coverageDeleteConfirm: 'Delete the coverage?',
  coverageDelete: 'Delete the coverage',
  coverageDeleteFromListConfirm: 'Delete the coverages from the list?',
  endCourierSessionConfirm:
    'Are you sure you want to finish the shift? All uncompleted routes will be canceled.',

  // notifications & phrases
  addressIsNotDefined: 'The address is not determined',
  cannotCallTheClient: 'Courier could not reach the customer by phone',
  courierIsNotSelected: 'Did not select a courier and a car',
  sessionWillBeCreateAutomatically: 'The shift will be created automatically',
  transportIsNotCompareToWarehouse: 'The stock does not match the one available for the car',
  courierIsNotCompareToWarehouse: 'The stock does not match the one available for the courier',
  transportIsNotCompareToDeliveryMethod:
    'The delivery method does not match the one available for the car',
  thereIsActiveRoutesInSession: 'Shift has uncompleted routes',
  locationHasNoRates: 'The location does not have active tariffs',
  letsCreateRoute: 'Drag tasks here, to make a route.',
  thereAreNoZones: 'There are no active zones',
  thereAreSomeProblems: 'Problems detected',
  noSectionFound: 'Section is not available',
  accessDenied: 'Access denied',
  noTasks: 'No tasks',
  notDefined: 'Not determined',
  thereAreTasksWithNotAvailableDeliveryMethodsForTransport:
    'The car will not go: there are tasks with an unavailable delivery method',
  thereAreTasksWithNotAvailableWarehouseForTransport:
    'The car will not go: there are tasks with an unavailable stock',
  thereAreTasksWithNotAvailableWarehouseForCourier:
    'The courier will not go; there are tasks with an unavailable stock',
  letsCreateCourierSession: 'Press "Add shift", to plan a shift for courier for the day',
  warehouseCreatedWithNoCoords:
    'Stock is created with geodecoding error. Obtain the correct address of the stock for activation',
  maxDelayTimeMeaning:
    'If set to 0, late arrival is prohibited. If the field is empty, there are no limits',
  maxLeadTimeMeaning:
    'If set to 0, early arrival is prohibited. If the field is empty, there are no limits',
  fullDayRoutesMeaning: 'Distribute orders for the whole day',
  searchByFullName: 'Search by full name',
  defaultSort: 'Sort by default',
  ascendingAmountSort: 'Amount in ascending order',
  descendingAmountSort: 'Amount in descending order',
  ascendingWeightSort: 'Weight in ascending order',
  descendingWeightSort: 'Weight in descending order',
  ascendingVolumeSort: 'Volume in ascending order',
  descendingVolumeSort: 'Volume in descending order',
  couriersSuccessfullySend: 'Couriers sent for loading',
  courierHasProcessingRoute: 'The courier has an executable route',
  courierHasProcessingSession: 'The courier has an executable shift',
  readyToSendCouriers: 'Couriers ready for shipping',
  noCouriersToSend: 'There are no couriers ready for shipping',
  addExtraTask: 'Create an additional task',
  taskCreationReason: 'Describe the reason',
  extraTaskPreRequest: 'Did the courier meet with the customer?',
  extraTaskReasons: 'An additional task is created if you need to:',
  extraTaskReason1: 'Issue a refund',
  extraTaskReason2: 'Deliver products',
  extraTaskReason3: 'Replace products',
  extraTaskWarning: 'These task will not be shown in the Accounting System.',
  extraTaskAction: 'If the reason is somewhat different, contact Technical Support',
  accountSystems: 'Account systems',
  fullScreenMode: 'Full screen mode',
  distance: 'Planned route distance',
  distanceShort: 'Route distance',
  distanceUnit: 'km',
  getAdjacentCoverages: 'Show adjacent coverages',
  menu: 'Menu',
  // TODO нужен перевод на другие языки
  countries: 'Countries',
  country: 'Country',
  cities: 'Cities',
  countryEditSuccess: 'Country successfully changed',
  countryCreateSuccess: 'Country successfully created',
  countryEditTitle: 'Edit country',
  countryCreateTitle: 'Add country',
  cityEditSuccess: 'City successfully changed',
  cityCreateSuccess: 'City successfully created',
  cityEditTitle: 'Edit city',
  cityCreateTitle: 'Add city',
  warehouse_one: 'warehouse',
  warehouse_few: 'warehouses',
  warehouse_many: 'warehouses',
  warehouse_other: 'warehouses',
  courier_one: 'courier',
  courier_few: 'couriers',
  courier_many: 'couriers',
  courier_other: 'couriers',
  clusters: 'Clusters',
  listOfClusters: 'List of clusters',
  createClusters: 'Create a cluster',
  information: 'Information',
  setting: 'Settings',
  editSuccess: 'Changes saved',
  clusterCreateSuccess: 'Cluster created successfully',
  clusterEditTitle: 'Editing a cluster',
  clusterCreateTitle: 'Create a cluster',
  clusterEditTitleSettings: 'Editing settings',
  next: 'Next',
  city: 'City',
  locality: 'Locality',
  enterCountry: 'Enter country',
  enterLocality: 'Enter locality',
  enterClusterName: 'Enter cluster name',
  step: 'Step',
  startDateAndTime: 'Start date and time',
  routingStartPlan: 'Routing start plan',
  startingPoint: 'Starting point',
  startingPointPlaceholder: 'From which point to start the calculation',
  shearTime: 'Shear time',
  shearTimePlaceholder: 'Enter shear time',
  fieldPlaceholder: 'Field placeholder',
  newDeliveryMethod: 'New delivery method',
  order_assembly_end: 'Assembly end',
  order_slot_start: 'Slot start',
  addCouriers: 'Add couriers',
  noClusters: 'No clusters',
  byTitle: 'By title',
  byCourier: 'By courier',
  enterShop: 'Select store',
  clusterCreated: 'The cluster is created',
  courierAverageSpeed: 'Courier average speed',
  orderActualStrategy: 'Order actual strategy',
  fast_mine_mode: 'Automatic',
  courier_council_mode: 'Courier council',
  cluster_mode: 'Cluster mode',
  enterClientGroup: 'Enter client group',
  searchByNumberContractor: 'Search by task number or contractor name',
  planActual: 'Plan / actual',
  LoadingAtWarehouse: 'Route start',
  completingRoute: 'Completing the route',
  calculateByYandex: 'Сalculate by Yandex',
  calculateByYandexAll: 'Calculate all by Yandex',
  planUploadTime: 'Scheduled date and time of shipment of goods from the warehouse',
  forTheAccountant: '(Russia’s accounting only)',
  enterDateTime: 'Select date and time',
  latitudeValidator: 'The latitude value must be in the range {-90:+90}',
  longitudeValidator: 'The longitude value must be in the range {-180:+180}',
  reasonDelay: 'Reason delay',
  reasonsDelay: 'Reasons delay',
  requiredComment: 'Required comment',
  titleOfReasonDelay: 'Title of reason delay',
  deleteReasonDelay: 'Delete reason delay',
  reasonDelayEditSuccess: 'Reason delay successfully changed',
  reasonDelayCreateSuccess: 'Reason delay successfully created',
  reasonDelayEditTitle: 'Edit reason delay',
  reasonDelayCreateTitle: 'Add reason delay',
  comment_one: 'comment',
  comment_few: 'comments',
  comment_many: 'comments',
  comment_other: 'comments',
  routeStartTime: 'Route start time',
  routeStart: 'Route start',
  intercom: 'Intercom',
  intercomNotWorking: 'is not working, call me',
  intercomCode: 'code',
  barrier: 'Barrier',
  barrierExist: 'Yes',
  barrierNotExist: 'No',
  barrierCall: 'Yes, call me',
  paymentMethods: 'Payment methods',
  paymentMethod: 'Payment method',
  paymentMethodEditSuccess: 'Payment method successfully changed',
  paymentMethodCreateSuccess: 'Payment method successfully created',
  paymentMethodEditTitle: 'Edit payment method',
  paymentMethodCreateTitle: 'Add payment method',
  paymentMethodCode: 'Code',
  paymentMethodWebName: 'Payment method',
  paymentMethodMobName: 'Payment method in app',
  paymentMethodNoPermissions:
    'An order can be taken in the route if the order is in payment status “Not Paid”',
  paymentStatus: 'Payment status',
  paymentPaid: 'Paid',
  paymentNotPaid: 'Not paid',
  infoByAddress: 'Information about address',
  noTariffByAddress: 'The address is not included in the service area',
  tariffNotSet: 'The tariff is not set up',
  setUpTariffs: 'Set up a tariff',
  showTariff: 'View tariffs',
  notificationsFromCouriers: 'Notifications from couriers',
  enableNotificationsFromCouriers: 'Enable notifications from couriers',
  profileDeactivation: 'Profile deactivation',
  deactivationDate: 'Deactivation date',
  selfEmployedCourier: 'Self-employed courier',
  priorityOfReasonDelay: 'Priority',
  enterPriority: 'Enter value (from 1 to 9999)',
  priorityNumberValidator: 'Enter value from 1 to 9999',
  baseTime: 'Base unloading time',
  unloadingTimeCalculation: 'Unloading time calculation',
  orderWeightForExtraDelay: 'Order weight for extra delay',
  enterOrderWeightForExtraDelay: 'Enter order weight for extra delay',
  orderUnloadingExtraDelay: 'Order unloading extra delay',
  enterOrderUnloadingExtraDelay: 'Enter order unloading extra delay',
  more: 'More',
  now: 'Now',
  selectCurrency: 'Select currency',
  areYouSureYouWantToRemoveTheCoverage: 'Are you sure you want to remove the coverage?',
};
